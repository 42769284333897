import { convertDatastringToWhiteElephantGameData, GiftSelectionData, UserData, WhiteElephantGameData } from "../constants/dataConstants";
import { updateUserGames } from "./fetchUserUtils";


  export async function fetchGiftsForWhiteElephantGame(firebaseUser: any, thisGame: WhiteElephantGameData) {
    // Get gifts from gameId
    const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/giftSelection/?gameId=${thisGame.id}`;
    
    const bearerToken = await firebaseUser.getIdToken();
    const response = await fetch(targetUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
    })
    .catch((error) => {
      console.log(`error: ${error}`);
      return null;
    });

    if(response) {
      const responseBody = await response.text();
      const  giftSelectionData: GiftSelectionData[] = JSON.parse(responseBody);
      // console.log(`data = ${JSON.stringify(data)}`);
      return giftSelectionData;
    } else {
      return null;
    }
  }

  export async function fetchWhiteElephantGameByGameCode(firebaseUser: any, gameCode: string) {
    gameCode = gameCode.toUpperCase();

    // Get White Elephant Games
    const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/?gameCode=${gameCode}`;

    const bearerToken = await firebaseUser?.getIdToken();
    const response = await fetch(targetUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
    })
    if (response.ok) {
      const responseBody = await response.text();
      const { data } = JSON.parse(responseBody);

      const foundGame: WhiteElephantGameData = convertDatastringToWhiteElephantGameData(JSON.stringify(data));
      return foundGame;
    } else {
      return undefined;
    }
  }

  export async function updateWhiteElephantGamePlayers(firebaseUser: any, gameId: string, newArrayOfPlayers: string[]) {
    const postToGameUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/?gameId=${gameId}`;

    const bearerToken = await firebaseUser?.getIdToken();
    const response = await fetch(postToGameUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        players: newArrayOfPlayers,
      })
    });
    return response.ok;
  }

  export async function joinGame(firebaseUser: any, myUserData: UserData, whiteElephantGame: WhiteElephantGameData) {
    // Attempt to join a game
    if (myUserData.myGameIds.indexOf(whiteElephantGame.id) < 0) {
      const newArrayOfPlayers: string[] = whiteElephantGame.players;
      newArrayOfPlayers.push(myUserData.id);

      const isUpdateGamePlayersSuccessful: boolean = await updateWhiteElephantGamePlayers(firebaseUser, whiteElephantGame.id, newArrayOfPlayers);

      if (isUpdateGamePlayersSuccessful) {
        const newMyGameIds: string[] = Object.assign([], myUserData.myGameIds);
        newMyGameIds.push(whiteElephantGame.id);
          // console.log(`newMyGameIds: ${JSON.stringify(newMyGameIds)}`);

        const isUpdateOfUserGamesSuccessful = await updateUserGames(firebaseUser, myUserData.id, newMyGameIds);
        
        if (isUpdateOfUserGamesSuccessful) {
          const newMyUserData = myUserData;
          let newMyUserGameIds: string[] = [];
          if(newMyUserData) {
            if( newMyUserData.myGameIds.length > 0) {
              newMyUserGameIds = newMyUserData.myGameIds;
            }
            newMyUserGameIds.push(whiteElephantGame.id);
            newMyUserData.myGameIds = newMyUserGameIds;
            // console.log(`joinGameWithCode - new myUserData: ${JSON.stringify(newMyUserData)}`);
            return newMyUserData;
          }
        } // update of user with new array of games successful
      } // update of white elephant game players successful
    } // user is not joining a game they are already in
    else {
      // user is already in this game
      return myUserData;
    }
    return false;
  }
