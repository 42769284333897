import { UserData } from "../constants/dataConstants";


export async function createUser(firebaseUser: any, userId: string, authedUid: string, displayName: string | null, userEmail: string | null) {
  const targetUrl: string = 'https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/user/';
  const bearerToken = await firebaseUser.getIdToken();
  const response = await fetch(targetUrl, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization':`Bearer ${bearerToken}`,
    },
    body: JSON.stringify({
      id: userId,
      name: displayName,
      email: userEmail,
      authenticatedUid: authedUid,
    })
  })
  .catch((error) => {
    console.log(`PUT failed with error: ${JSON.stringify(error)}`);
    return null;
  });
  if(response?.ok)
    return true;
  else
    return false;
}

export async function fetchPlayerById(firebaseUser: any, userId: string) {
  // Get Player name from playerId
  const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/user/?userId=${userId}`;
  
  const bearerToken = await firebaseUser.getIdToken();
  const response = await fetch(targetUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization':`Bearer ${bearerToken}`,
    },
  })
  .catch((error) => {
    console.log(`error: ${error}`);
    return null;
  });

  if(response) {
    const responseBody = await response.text();
    const responsePayload = JSON.parse(responseBody);
    const data: UserData = responsePayload.data;
    return data;
  } else {
    return null;
  }
}

export async function fetchPlayerByAuthId(firebaseUser: any) {
  if(firebaseUser) {
    // Get Player name from playerId
    const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/user/?authenticatedUid=${firebaseUser.uid}`;
    
    const bearerToken = await firebaseUser.getIdToken();
    const response = await fetch(targetUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
    })
    .catch((error) => {
      console.log(`error: ${error}`);
      return null;
    });

    if(response) {
      const responseBody = await response.text();
      const responsePayload = JSON.parse(responseBody);
      const data: UserData = responsePayload.data;

      // console.log(`data = ${JSON.stringify(data)}`);
      return data;
    } else {
      return null;
    }
  }
  return null;
}

export async function updateUserGames(firebaseUser: any, myUserId: string, newArrayOfGameIds: string[]) {
  const postToUserUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/user/?userId=${myUserId}`;

  const bearerToken = await firebaseUser?.getIdToken();
  const response = await fetch(postToUserUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization':`Bearer ${bearerToken}`,
    },
    body: JSON.stringify({
      myGameIds: newArrayOfGameIds,
    })
  });
  return response.ok;
}

export function isUserComplete(userData?: UserData) {
  if(userData) {
    if(!userData.name || userData.name?.length < 1) {
      return false;
    }
    if(!userData.email || userData.email?.length < 3) {
      return false;
    }
    if(!userData.address1 || userData.address1?.length < 2) {
      return false;
    }
    if(!userData.city || userData.city?.length < 1) {
      return false;
    }
    if(!userData.state || userData.state?.length < 1) {
      return false;
    }
    if(!userData.zipcode) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}
