import giftBox1 from '../assets/default_box/gift-box-1.jpg';
import giftBox2 from '../assets/default_box/gift-box-2.jpg';
import giftBox3 from '../assets/default_box/gift-box-3.jpg';
import giftBox4 from '../assets/default_box/gift-box-4.jpg';
import { storageRef } from '../firebase';

export function getDefaultImageSrc(defaultImageId: string) {
  if (defaultImageId === "giftBox1")
    return giftBox1;
  else if (defaultImageId === "giftBox2")
    return giftBox2;
  else if (defaultImageId === "giftBox3")
    return giftBox3;
  else
    return giftBox4;
}

export function downloadImages(imageIds: string[]) {
  imageIds.forEach((imageId: string) => {
    if(!isOneOfDefaultGiftBoxes(imageId) && imageId.indexOf('m.media-amazon.com') < 0) {
      const imageRef = storageRef.child(imageId);
      imageRef.getDownloadURL().then((url) => {
        // inserted into an <img> element:
        const img = document.getElementById(imageId) as HTMLImageElement;
        if (img) {
          img.src = url;
        }
      });
    }
  });
}

export function randomGiftBoxImageId() {
  const numberOfDefaultImages = 4;
  const randNumber = Math.floor(Math.random()*10) % numberOfDefaultImages;
  if (randNumber === 0)
    return "giftBox1";
  else if (randNumber === 1)
    return "giftBox2";
  else if (randNumber === 2)
    return "giftBox3";
  else
    return "giftBox4";
}

export function isOneOfDefaultGiftBoxes(imageId: string) {
  if(['giftBox1', 'giftBox2', 'giftBox3', 'giftBox4'].indexOf(imageId) > -1) 
    return true;
  else
    return false;
}
