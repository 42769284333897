
import React from "react";
import { Button, Spinner, Tab, Tabs } from "react-bootstrap";

import { GiftSelectionData, ResponseStatus, UserData, WhiteElephantGameData, WhiteElephantGameFormat, WhiteElephantGameStatus } from "../constants/dataConstants";
import GiftPickerView from "../gifts/GiftPickerView";
import InfoModal from "../modals/InfoModal";
import ImageDownloadComponent from "../utils/imageDownloadComponent";
import { downloadImages } from "../utils/imageUtils";
import { checkGameStatus, checkPlayerStatus, connectToStatus, disconnectFromCheckPlayerStatus, disconnectFromStatus, updateGameStatus } from "../utils/StatusUtils";

import './GameRoomView.css';

const ReactShapes = require('react-shapes');

export enum TabTypes {
  GameRoom = 'Game Room',
  MyGift = 'My Gift',
  GameRules = 'Game Rules',
}

interface BaseComponentState {
  isPlayerOnline: {[playerId: string]: boolean};
  myGiftSelection?: GiftSelectionData;
  isSending: boolean;
  shouldShowModal: boolean;
  modalTitle: string;
  modalBody: string;
}

interface BaseComponentProps {
  firebaseUser: any;
  myUserId: string;
  currentGame: WhiteElephantGameData;
  playerIdToPlayer: {[key: string]: UserData};
  onGameStartCallback: (currentGame: WhiteElephantGameData, activePlayers: string[]) => any;
  onGiftSaveCallback: (giftSelection: GiftSelectionData) => any;
  onGameStatusChangeCallback: (newGameStatus: string) => any;
  onTabChangeCallback?: (currentTab: TabTypes) => any;
  myGiftSelection?: GiftSelectionData;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class GameRoomView<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      isPlayerOnline: {},
      myGiftSelection: this.props.myGiftSelection,
      isSending: false,
      shouldShowModal: false,
      modalTitle: '',
      modalBody: '',
    }
  }

  componentDidMount () {
    // Check Player Statuses
    this.attemptToConnectStatus();
    // console.log(`playerIdToPlayer: ${JSON.stringify(this.props.playerIdToPlayer)}`);
    if (!this.state.myGiftSelection) {
      this.setState({
        shouldShowModal: true,
        modalTitle: 'Gift Missing!',
        modalBody: 'Select a gift and remember to save it before the game begins',
      });
    }
  }
  componentWillUnmount () {
    // Disconnect from Player Status Checks
    disconnectFromStatus(this.props.myUserId);

    Object.keys(this.props.playerIdToPlayer).forEach((playerId) => {
      disconnectFromCheckPlayerStatus(playerId);
    });
  }

  render() {
    return (
      <div className="FullSize">
        {this.state.shouldShowModal && <InfoModal shouldShow={this.state.shouldShowModal} title={this.state.modalTitle} bodyMessage={this.state.modalBody} onClose={() => {this.setState({ shouldShowModal: false })}} />}
        <Tabs defaultActiveKey="GameRoom" id="GameRoomView-tab">
          <Tab eventKey="GameRoom" title="Game Room" onEnter={() => {
            if (this.props.onTabChangeCallback) {
              this.props.onTabChangeCallback(TabTypes.GameRoom);
            }
          }}>
            {this.renderGameRoom()}
          </Tab>
          <Tab eventKey="MyGift" title="My Gift" onEnter={() => {
            if (this.props.onTabChangeCallback) {
              this.props.onTabChangeCallback(TabTypes.MyGift);
            }
          }}>
            <div className={"Padding15pxView Background-Img"}>
              <GiftPickerView firebaseUser={this.props.firebaseUser} myUserId={this.props.myUserId} currentGame={this.props.currentGame} myGiftSelection={this.props.myGiftSelection} onSaveCallback={(giftSelection: GiftSelectionData) => {
                this.setState({ myGiftSelection: giftSelection });
                this.props.onGiftSaveCallback(giftSelection);
                }} />
            </div>
          </Tab>
          <Tab eventKey="GameRules" title="Game Rules" onEnter={() => {
            if (this.props.onTabChangeCallback) {
              this.props.onTabChangeCallback(TabTypes.GameRules);
            }
          }}>
            {this.renderGameRules()}
          </Tab>
        </Tabs>
      </div>
    );
  }

  renderGameRoom() {
    const players = Object.values(this.props.playerIdToPlayer);
    const oldDate = new Date();
    const timeInMinutes = 15;
    const timeInFuture = new Date(oldDate.getTime() + timeInMinutes * 60000);
    if (this.props.currentGame.imageIds) {
      downloadImages([this.props.currentGame.imageIds[0]]);
    }
    return (
      <div className={"Background-Img FullSize Center-Middle Center-Horizontal ColumnFlex"}>
        {this.props.currentGame.imageIds?.[0] && 
        <div className={"Image"} >
        <ImageDownloadComponent imageIdPath={this.props.currentGame.imageIds[0]} className={"Image"} />
        </div>}
        <div className={"Center-Middle"}>
          <div className={"PlayerStatusContainer Semi-Transparent-Background ScrollY"}>
            <div className={"FullWidth "}>
              {`( ${this.getNumberOfActivePlayers()} / ${players.length} active players)`}
            </div>
            <div className={"Center-Middle AllUserStatusContainer"}>
              {players.map((player) => this.renderUserStatus(player))}
            </div>
          </div>
        </div>
        {this.props.currentGame.hostId === this.props.myUserId && 
        this.props.currentGame.gameTime < timeInFuture && 
            <Button variant='outline-primary' className={"Form-Button MarginLeft-2"} onClick={async () => {
                this.setState({ isSending: true });
                updateGameStatus(this.props.currentGame.id, WhiteElephantGameStatus.started, async (responseStatus: ResponseStatus) => {
                  if (responseStatus === ResponseStatus.success) {
                    await this.props.onGameStartCallback(this.props.currentGame, this.getActivePlayerIds()).then(() => {
                      this.setState({ isSending: false });
                    });
                  } else {
                    this.setState({ isSending: false });
                  }
                });
              }
            } disabled={this.state.isSending}>
              {this.state.isSending && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
              Start your party!
            </Button>}
      </div>
      );
  }

  renderUserStatus = (player: UserData) => (
    this.state.isPlayerOnline[player.id] === true ? 
    <div className={"UserStatusContainer"} key={`player-${player.id}`}>
      <div className={"Column CircleIcon"}>
        <ReactShapes.Circle r={6} fill={{color:'#00b90f'}} stroke={{color:'#686868'}} strokeWidth={0} />
      </div>
      <div className={"Column PlayerName"}>
        {player.name}
      </div>
    </div> :
    <div className={"UserStatusContainer"} key={`player-${player.id}`}>
      <div className={"Column CircleIcon"}>
        <ReactShapes.Circle r={6} fill={{color:'#686868'}} stroke={{color:'#686868'}} strokeWidth={0} />
      </div>
      <div className="Column PlayerName">
        <div className={"PlayerName"}>
        {player.name}
        </div>
      </div>
    </div>
  );

  renderGameRules() {
    return (
      <div className="">
        {this.props.currentGame.gameRules?.['Format'] === WhiteElephantGameFormat.classic ? (
          <div className={"FullSize TextLeft Background-Img Padding15pxView RulesFont"}>
            <h3>Classic Game Rules</h3>
            <li className="MarginBottom-10px">When the game starts, all gifts will be hidden in their wrapped forms. </li>
            <li className="MarginBottom-10px">Players take turns opening, taking, or keeping gifts. </li>
            <li className="MarginBottom-10px">When a player chooses to take a gift from another player, the player swaps gifts with the other player. The other player whose gift was taken can keep this new gift, open a new gift (if there are unopened gifts), or take a gift from another player. </li>
            <li className="MarginBottom-10px">After a gift is taken, the gift is locked for that round and no other player can take that gift. The round ends when a player opens a new gift, keeps their gift, or all opened gifts are locked. When a new round begins, all opened gifts are no longer locked. </li>
            <li className="MarginBottom-10px">Once the game starts, the first player must choose to open a gift. When all gifts are opened, the game moves into a bonus round and the first player can choose to keep their gift or take a gift from another player. </li>
          </div>
        ) : (
          <div className={"FullSize TextLeft Background-Img"}>
            <h3>Single Steal Game Rules</h3>
            <li>When the game starts, all gifts will be hidden in their wrapped forms. </li>
            <li>Players take turns opening, taking, or keeping gifts. </li>
            <li>When a player chooses to take a gift from another player, the player swaps gifts with the other player. </li>
            <li>Once the game starts, the first player must choose to open a gift. When all gifts are opened, the game moves into a bonus round and the first player can choose to keep their gift or take a gift from another player. </li>
          </div>
        )}
      </div>
    );
  }

  private attemptToConnectStatus() {
    // Check Player Statuses
    connectToStatus(this.props.myUserId);

    Object.keys(this.props.playerIdToPlayer).forEach((playerId) => {
      checkPlayerStatus(playerId, this.checkPlayerStatusCallback)
    });

    // Check Game Status
    if (this.props.currentGame.hostId !== this.props.myUserId) {
      checkGameStatus(this.props.currentGame.id, this.gameStatusCallback);
    }
  }

  private getNumberOfActivePlayers() {
    const isPlayerOnline: {[playerId: string]: boolean} = this.state.isPlayerOnline;
    let numOfActivePlayers = 0;
    Object.values(isPlayerOnline).forEach((isOnline) => {
      if (isOnline) {
        numOfActivePlayers++;
      }
    });
    return numOfActivePlayers;
  }

  private getActivePlayerIds(): string[] {
    const isPlayerOnline: {[playerId: string]: boolean} = this.state.isPlayerOnline;
    const activePlayerIds: string[] = [];
    for (const playerId of Object.keys(isPlayerOnline)) {
      if (isPlayerOnline[playerId]) {
        activePlayerIds.push(playerId);
      }
    }
    return activePlayerIds;
  }

  private checkPlayerStatusCallback = (playerId: string, playerState: string, _last_changed?: number) => {
    // console.log(`playerId status - ${playerId} = ${playerState}`)
    const isPlayerOnline: {[playerId: string]: boolean} = this.state.isPlayerOnline;
    isPlayerOnline[playerId] = playerState === 'online';
    this.setState({ isPlayerOnline });
  }

  private gameStatusCallback = (gameStatus: string, last_changed?: number) => {
    if (this.props.currentGame.status !== gameStatus) {
      this.props.onGameStatusChangeCallback(gameStatus);
    }
  }
}
