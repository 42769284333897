
import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

import { UserData } from "../constants/dataConstants";
import { checkPlayerStatus, connectToStatus, disconnectFromCheckPlayerStatus, disconnectFromStatus } from "../utils/StatusUtils";
import faceIcon from "../assets/icons/face-black-icon.png";

import './GamePlayerStatusView.css';

const ReactShapes = require('react-shapes');

interface BaseComponentState {
  isPlayerOnline: {[playerId: string]: boolean};
}

interface BaseComponentProps {
  firebaseUser: any;
  myUserId: string;
  playerIdToPlayer: {[key: string]: UserData};
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class GamePlayerStatusView<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      isPlayerOnline: {},
    }
  }

  async componentDidMount () {
    // Check Player Statuses
    this.attemptToConnectStatus();
  }
  componentWillUnmount () {
    // Disconnect from Player Status Checks
    disconnectFromStatus(this.props.myUserId);

    Object.keys(this.props.playerIdToPlayer).forEach((playerId) => {
      disconnectFromCheckPlayerStatus(playerId);
    });
  }
  

  render() {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 200 }}
        overlay={this.renderTooltip(Object.values(this.props.playerIdToPlayer))}
      >
        <Button variant='light'>
          {`Players `}
          <img src={faceIcon} alt="active players" className="Icon" />
        </Button>
      </OverlayTrigger>
    );
  }

  renderTooltip = (players: UserData[]) => (
    <Popover id="button-tooltip" >
      <Popover.Content>
        {players.map((player) => this.renderRow(player))}
      </Popover.Content>
    </Popover>
  );

  renderRow = (player: UserData) => (
    this.state.isPlayerOnline[player.id] === true ? 
    <div className={"RowContainer TooltipWidth"} key={`player-${player.id}`}>
      <div className={"Column CircleIcon"}>
        <ReactShapes.Circle r={6} fill={{color:'#00b90f'}} stroke={{color:'#686868'}} strokeWidth={0} />
      </div>
      <div className={"Column"}>
        <div className={"PlayerName"}>
          {player.name}
        </div>
      </div>
    </div> :
    <div className={"RowContainer TooltipWidth"} key={`player-${player.id}`}>
      <div className={"Column CircleIcon"}>
        <ReactShapes.Circle r={6} fill={{color:'#686868'}} stroke={{color:'#686868'}} strokeWidth={0} />
      </div>
      <div className="Column PlayerName">
        <div className={"PlayerName"}>
        {player.name}
        </div>
      </div>
    </div>
  );

  private attemptToConnectStatus() {
    // Check Player Statuses
    connectToStatus(this.props.myUserId);

    Object.keys(this.props.playerIdToPlayer).forEach((playerId) => {
      checkPlayerStatus(playerId, this.checkPlayerStatusCallback)
    });
  }

  private checkPlayerStatusCallback = (playerId: string, playerState: string, last_changed?: number) => {
    const isPlayerOnline: {[playerId: string]: boolean} = this.state.isPlayerOnline;
    isPlayerOnline[playerId] = playerState === 'online';
    this.setState({ isPlayerOnline });
  }
}