
import React from "react";
import { Button, Modal, Form, InputGroup, Spinner } from "react-bootstrap";
import * as uuid from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import './GiftFinderModal.css';

import { GiftSelectionCandidate, ShoppingSites } from "../constants/dataConstants";
import searchIcon from '../assets/icons/search-black.png';
import { AmazonSearchResult, SearchData } from "../constants/amazon-search-result";
import searchAmazon from "../amazon-search/search";


interface BaseComponentState {
  shouldShow: boolean;
  searchString: string;
  amazonSearchData?: SearchData;
  isSearching: boolean;
}

interface BaseComponentProps {
  shouldShow: boolean;
  onGiftSelectionCallback?: (giftSelectionCandidate: GiftSelectionCandidate) => any;
  onCloseCallback?: () => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class GiftFinderModal<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      shouldShow: this.props.shouldShow,
      searchString: '',
      isSearching: false,
    }
  }

  render() {
    return (
      <Modal show={this.state.shouldShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Find a gift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSearchBar">
              <InputGroup className={"Form-Money-ProductPrice FullWidth"}>
                <Form.Control type="text" onChange={val => {
                  this.setState({ searchString: val.target.value });
                }} placeholder="Search for gift..."
                onKeyPress={async (event: React.KeyboardEvent<HTMLInputElement>) => { await this.handleKeyDown(event)}}
                />
                <InputGroup.Append>
                  <img src={searchIcon} alt="Search Icon" className="FilledYellow" onClick={async () => {
                    await this.searchAmazonByName(this.state.searchString);
                    }} />
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            {this.state.amazonSearchData && 
            <div className="BodyArea">
              {this.state.isSearching ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : this.state.amazonSearchData.searchResults.map((result) => this.renderAmazonSearchResultRow(result)) }
            </div>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderAmazonSearchResultRow(searchResult: AmazonSearchResult) {
    const isSinglePrice: boolean = searchResult.prices.length === 1;
    return (
      <div key={`resultRow-${searchResult.productUrl}`} className="FullWidth">
        <div className="ColumnContainer" onClick={() => this.onClickSearchedItem(searchResult)}>
          <div className="SearchResult-LeftColumn">
            <img src={searchResult.imageUrl} alt={`${searchResult.title}`} className="SearchResult-Image" />
          </div>
          <div className="SearchResult-RightColumn">
            <div className="SearchResult-Title">{searchResult.title}</div>
            <div className="SearchResult-Text">Stars {searchResult.rating.score} / {searchResult.rating.outOf}</div>
            {isSinglePrice ? 
            <div className="SearchResult-Text">${searchResult.prices[0]?.price}</div> : 
            <div className="SearchResult-Text">${searchResult.prices[0]?.price} - {searchResult.prices[1]?.price}</div>}
          </div>
        </div>
        <div className={"FullWidth Center-Horizontal"}>
          <Button variant='primary' className={"MarginTop"} onClick={() => {
            window.open(this.generateMyAmazonUrlFromProductUrl(searchResult.productUrl), "_blank")
          }} >
            View on Amazon
          </Button>
          <Button variant='primary' className={"MarginTop MarginLeft-3"} onClick={() => {
            this.onClickSearchedItem(searchResult);
          }}>
            Select
          </Button>
        </div>
        <hr />
      </div>
    );
  }

  private generateMyAmazonUrlFromProductUrl(url: string) {
    const urlParts = url.split('ref=');
    return 'https://www.amazon.com' + urlParts[0] + 'ref=nosim?tag=elephantgifte-20';
  }

  private onClickSearchedItem(searchResult: AmazonSearchResult) {
    const urlParts = searchResult.productUrl.split('ref=');
    const amazonUrl = 'https://www.amazon.com' + urlParts[0] + 'ref=nosim?tag=elephantgifte-20';
    const giftSelectionCandidate: GiftSelectionCandidate = {
      id: uuid.v4(),
      productName: searchResult.title,
      url: amazonUrl,
      price: searchResult.prices[0].price,
      shoppingSite: ShoppingSites.amazon,
      giftImageIds: [searchResult.imageUrl],
      createdAt: new Date(),
    } as GiftSelectionCandidate;
    this.props.onGiftSelectionCallback?.(giftSelectionCandidate);
  }


  private handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await this.searchAmazonByName(this.state.searchString);
    }
  }

  searchAmazonByName = async (productName: string) => {
    this.setState({isSearching: true});
    // TODO: Replace with server call to get search results

    await searchAmazon(productName, {includeSponsoredResults: false}).then((data: SearchData) => {
      console.log(data);
      console.log(data.pageNumber)    // 1
      console.log(data.searchResults?.[0]?.title, data.searchResults?.[0]?.imageUrl);
      this.setState({ amazonSearchData: data, isSearching: false });
    });
  }

  private handleClose = () => {this.setState({ shouldShow: false }, () => {
    if(this.props.onCloseCallback)
      this.props.onCloseCallback();
  })};
}