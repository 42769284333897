import React from 'react';
import { Button, Spinner, Form, Carousel, Dropdown, DropdownButton } from 'react-bootstrap';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './WhiteElephantGame.css';
import { convertDatastringToWhiteElephantGameData, UserData, WhiteElephantGameData, WhiteElephantGameStatus, WhiteElephantLocalStorageKey } from '../constants/dataConstants';
import { AuthContext } from '../provider/AuthProvider';
import { auth, } from '../firebase';
import logoRed from '../assets/logo-red.png';
import giftBoxes5 from '../assets/default_game/gift-boxes-5.jpg';
import giftBoxes6 from '../assets/default_game/gift-boxes-6.jpg';
import giftBoxes7 from '../assets/default_game/gift-boxes-7.jpg';
import menuBlack from '../assets/icons/menu-black.png';
import { HOME, PLAY_WHITE_ELEPHANT } from '../constants/routes';
import { fetchPlayerByAuthId, isUserComplete } from '../utils/fetchUserUtils';
import EditAccountModal from '../home/EditAccountModal';
import { downloadImages } from '../utils/imageUtils';
import { updateGameStatus } from '../utils/StatusUtils';
import DynamicTopBottomView from '../layout-components/DynamicTopBottomView';

interface BaseComponentState {
  firebaseUser?: any;
  myUserId?: string;
  myUserData?: UserData;
  isAllowed: boolean;
  gameCodeInput: string;
  isSendingGameCode: boolean;
  isSending: boolean;
  isLoading: boolean;
  isUserDataLoaded: boolean;
  isMenuToggled: boolean;
  shouldShowEditUserData: boolean;
  redirectHome: boolean;
  redirectToPlayWhiteElephant: boolean;
  gameName: string;
  gameTime: Date;
  myGames: WhiteElephantGameData[];
  gameIdToGame: {[key: string]: WhiteElephantGameData};
  selectedGame: WhiteElephantGameData;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = ChildProps;

export default class WhiteElephantGame<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      firebaseUser: null,
      isAllowed: false,
      isSending: false,
      isSendingGameCode: false,
      isLoading: true,
      isUserDataLoaded: false,
      isMenuToggled: false,
      shouldShowEditUserData: false,
      gameCodeInput: '',
      gameName: '',
      redirectHome: false,
      redirectToPlayWhiteElephant: false,
      gameTime: new Date((new Date()).getTime() + (1000 * 60 * 60 * 24)),
      myGames: [],
      gameIdToGame: {},
      selectedGame: {},
    };
  }

  private unsubscribeFromAuth: any = null;

  async componentDidMount () {
    this.unsubscribeFromAuth = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        // User is signed in.
        const myUserId = localStorage.getItem(WhiteElephantLocalStorageKey.myUserId) ?? undefined;
        // console.log(`####   myUserId: ${myUserId}`);
        this.setState({ firebaseUser, myUserId }, async () => {

          const myUserData = await fetchPlayerByAuthId(firebaseUser);
          if (myUserData) {
            // console.log(`myUserData: ${myUserData}`);
            if (myUserId) {
              this.setState({ myUserData, isUserDataLoaded: true });
            } else {
              this.setState({ myUserId: myUserData.id, myUserData, isUserDataLoaded: true });
            }
            await this.fetchGames();
          } else {
            // Unable to find firebase user in firestore User collection
          }

          const element = document.getElementById("TopOfScreen");
          element?.scrollIntoView({behavior: 'auto'});
        });
      } else {
        this.setState({ redirectHome: true });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    const { redirectHome } = this.state;
    if (redirectHome) {
      return <Redirect to={HOME} />;
    }
    if (this.state.redirectToPlayWhiteElephant) {
      localStorage.setItem(WhiteElephantLocalStorageKey.currentGame, JSON.stringify(this.state.selectedGame));
      return <Redirect push to={{
        pathname: PLAY_WHITE_ELEPHANT,
        state: { theGame: this.state.gameIdToGame[this.state.selectedGame.id] },
      }} />;
    }

    return (
      <div id="TopOfScreen" className="Base">
        <DynamicTopBottomView renderTopHalf={this.renderTop()} renderBottomHalf={this.renderBottom()} />
        {/* {this.renderTop()}
        {this.renderBottom()} */}
      </div>
    );
  }

  renderTopMenuRow() {
    return (
      <div className="Top-Row">
        <div className="TopLeftColumn">
          <div className={"Top-Left"}>
            <DropdownButton id="Menu" variant="transparent" title={
              <img src={menuBlack} alt='menu' className="Menu-Button" />
            }>
              <Dropdown.Item onClick={() => {
                // console.log(`Clicked  |  ${this.state.isUserDataLoaded}`);
                this.setState({ shouldShowEditUserData: true});
              }}>Edit Account</Dropdown.Item>
              <Dropdown.Item  onClick={async () => {
                await auth.signOut().then(() => {
                  localStorage.removeItem(WhiteElephantLocalStorageKey.myUserId);
                  localStorage.removeItem('ElephantGiftExchange-isAllowed');
                  this.setState({ firebaseUser: null });
                });
              }}>Log Out</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="TopMiddleColumn" />
        <div className="TopRightColumn">
          <img src={logoRed} alt='Elephant Gift Exchange' className="Logo-top" />
        </div>
      </div>
    );
  }
  
  renderTop() {
    return (
      <div className="Base">
        {(this.state.isUserDataLoaded && this.state.myUserData && (!isUserComplete(this.state.myUserData) || this.state.shouldShowEditUserData)) && 
          <EditAccountModal firebaseUser={this.state.firebaseUser} shouldShow={true} userData={this.state.myUserData!} isUserIncomplete={!isUserComplete(this.state.myUserData)} onCloseCallback={() => {this.setState({shouldShowEditUserData: false})}} />
        }
        <div className="Carousel-box">
          {this.state.isLoading && 
          <div className={"Center Carousel-box"}>
            <Spinner animation="border" role="status" />
            <div className="WhiteFont">
              {' Retrieving your game data...'}
            </div>
          </div>
          }
          {this.renderTopMenuRow()}
          {this.renderCarousel(this.state.myGames)}
        </div>
      </div>
    );
  }

  renderBottom() {
    return (
      <div className="Content">
        <div className={"Form-Base"}>
          <div className={"Column FullWidth"}>
            <div className={"Form-Div"}>
              <div className={"Fit-Center-Form WhiteElephantGame-Form"}>
                <div>
                  Already have a code from a friend or family member?
                </div>
                <div className="Code-Already-Input">
                <input type="text" placeholder="Enter Game code" className="GameCode-Input" onChange={val => this.setState({ gameCodeInput: val.target.value })} value={this.state.gameCodeInput} />
                <Button variant="primary" onClick={() => {
                  this.joinGameWithCode(this.state.gameCodeInput);
                  this.setState({ gameCodeInput: '' });
                }} disabled={this.state.isSendingGameCode}>
                {this.state.isSendingGameCode && <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />}
                Submit
                </Button>
              </div>
              </div>
            </div>

            <div className="Form-Div">
              <Form className={"Fit-Center-Form WhiteElephantGame-Form"}>
                <Form.Group controlId="formGameName">
                  <Form.Label className="Form-Label">Game Name: </Form.Label>
                  <Form.Control className="Form-Input" type="text" placeholder="Enter your game name" onChange={val => this.setState({ gameName: val.target.value })} value={this.state.gameName} />
                </Form.Group>
                <Form.Group controlId="formGameTime">
                  <Form.Label className="Form-Label">Game Time: </Form.Label>
                  <Form.Control className="Form-Input" type="datetime-local" value={moment(this.state.gameTime).format('yyyy-MM-DDTHH:mm')} onChange={val => {
                    this.setState({ gameTime: new Date(val.target.value) })
                }} />
                </Form.Group>
                <Button variant='outline-primary' className="CreateNewGame-Form-Button" onClick={async () => await this.createNewGame()} disabled={this.state.isSending}>
                  {this.state.isSending && <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />}
                  {!this.state.isSending && `Create New Game`}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCarousel(myGames: WhiteElephantGameData[]) {
    return (
      <Carousel className="Row-Game-Cards">
        {myGames.length === 0 ? 
          <Carousel.Item className="Card" key={'No-Games-Found'}>
            <img src={this.getDefaultImage(0)}
              alt={"No Games Found. Try creating a new game below."} 
              className='CardImage' />
            <Carousel.Caption className="Carousel-Text">
              <div>
                <h3>{`No Games Found`}</h3>
                <div className="TextPadding1">
                  {`Try creating a new game below.`}
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item> : 
          this.renderCarouselItems(myGames)
        }
      </Carousel>
    );
  }

  renderCarouselItems(myGames: WhiteElephantGameData[]) {
    // console.log(`renderCarouselItems: ${JSON.stringify(myGames)}`);
    myGames.forEach((game) => {
      if (game.imageIds && game.imageIds.length > 0) {
        downloadImages([game.imageIds[0]]);
      }
    });
    return (
      myGames.map((game, index) => {
        // console.log(`myGames ${index}: ${JSON.stringify(game)}`);
        return (
          <Carousel.Item className="Card" key={game.id}>
            <img src={this.getDefaultImage(index)}
              id={game.imageIds?.[0] ?? undefined}
              alt={game.gameName.toString()} 
              className='CardImage' />
            <Carousel.Caption className="Carousel-Text">
              <div>
                <h3>{game.gameName.toString()}</h3>
                <div className="TextPadding1">
                  {`Date: ${moment(game.gameTime).format('MMM DD, yyyy')}`}
                </div>
                <div className="TextPadding2">
                  {`Time: ${moment(game.gameTime).format('dddd hh:mm A')}`}
                </div>
                <div className="TextPadding1">
                  {`Game Format: ${game.gameRules?.['Format']}`}
                </div>
                <div className="TextPadding2">
                  {`Game Code: ${game.gameCode}`}
                </div>
                <Button variant="primary" className="Carousel-Button" onClick={() => this.handleCarouselClick(game)}>
                  {this.getButtonText(game.gameTime, game.status, game.hostId)}
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })
    );
  }

  handleCarouselClick = (game: WhiteElephantGameData) => {
    // console.log(`game: ${JSON.stringify(game)}`);
    localStorage.setItem(WhiteElephantLocalStorageKey.currentGame, JSON.stringify(game));
    this.setState({ 
      redirectToPlayWhiteElephant: true,
      selectedGame: game,
     });
  }

  getDefaultImage(index: number) {
    const numberOfDefaultImages = 3;
    const modulo = index % numberOfDefaultImages;
    if (modulo === 0)
      return giftBoxes5;
    else if (modulo === 1)
      return giftBoxes6;
    else
      return giftBoxes7
  }
  
  getButtonText(gameTime: Date, status: string, hostId: string) {
    // const hasBegun: boolean = gameTime < (new Date());
    const isFinished: boolean = status === WhiteElephantGameStatus.finished;
    if (isFinished) {
      return 'View Game Results';
    } else if (status === WhiteElephantGameStatus.not_started) {
      if (this.state.myUserId && hostId !== this.state.myUserId) {
        return 'Input Gift';
      }
      return 'View / Modify Game';
    } else {
      return 'Play Now';
    }
  }

  private createNewGame = async () => {
    const targetUrl: string = 'https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/';

    this.setState({ 
      isSending: true,
    })
    const bearerToken = await this.state.firebaseUser?.getIdToken();
    await fetch(targetUrl, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        gameName: this.state.gameName,
        hostId: this.state.myUserId,
        gameTime: new Date(this.state.gameTime),
        players: [this.state.myUserId],
        createdAt: new Date(),
      })
    })
    .then(async (response) => {
      const responseBody = await response.text();
      // console.log(`response: ${responseBody}`);
      const gameData = convertDatastringToWhiteElephantGameData(responseBody);
      // console.log(`gameData: ${gameData}`);
      updateGameStatus(gameData.id, gameData.status, () => {});

      const gameIdToGame: {[key: string]: WhiteElephantGameData} = this.state.gameIdToGame;
      gameIdToGame[gameData.id] = gameData;
      const myGames = this.state.myGames;
      myGames.push(gameData);
      // console.log(`new myGames: ${JSON.stringify(myGames)}`);

      this.setState({ 
        isSending: false,
        gameName: '',
        gameTime: new Date((new Date()).getTime() + (1000 * 60 * 60 * 24)),
        gameIdToGame,
        myGames,
      })
    })
    .catch((error) => {
      this.setState({ 
        isSending: false,
      })
    });
  }

  private async joinGameWithCode(gameCodeInput: string) {
    this.setState({ 
      isSendingGameCode: true,
    });
    gameCodeInput = gameCodeInput.toUpperCase();
    // console.log(`joinGameWithCode - beginning with ${gameCodeInput}`);

    // Get White Elephant Games
    const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/?gameCode=${gameCodeInput}`;

    const bearerToken = await this.state.firebaseUser?.getIdToken();
    await fetch(targetUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
    })
    .then(async response => {
      const responseBody = await response.text();
      const { data } = JSON.parse(responseBody);
      // console.log(`data = ${JSON.stringify(data)}`);
      // console.log(`data.length = ${JSON.stringify(data.length)}`);
      // console.log(`~~ data.gameTime = ${new Date(data[0].gameTime.toDate()).toLocalestring()}`);
      const foundGame: WhiteElephantGameData = convertDatastringToWhiteElephantGameData(JSON.stringify(data));

      if (this.state.myUserData && this.state.myUserData.myGameIds.indexOf(foundGame.id) > -1) {
        // User is attempting to join a game they are already a participant in
        this.setState({ 
          isSendingGameCode: false,
        });
      } else {
        const newArrayOfPlayers = foundGame.players;
        newArrayOfPlayers.push(this.state.myUserId!);
        const newGameValues = foundGame;
        newGameValues.players = newArrayOfPlayers;

        // console.log(`preparing to POST new player. newGameValues = ${JSON.stringify(newGameValues)}`);

        const postToGameUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/?gameId=${foundGame.id}`;
        await fetch(postToGameUrl, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization':`Bearer ${bearerToken}`,
          },
          body: JSON.stringify({
            players: newArrayOfPlayers,
          })
        })
        .then(async () => {
          // console.log(`joinGameWithCode - game: ${JSON.stringify(newGameValues)}`);

          let newMyGameIds: string[]  = [];
          if (this.state.myUserData) {
            newMyGameIds = Object.assign([], this.state.myUserData.myGameIds);
            newMyGameIds.push(foundGame.id);
            // console.log(`newMyGameIds: ${JSON.stringify(newMyGameIds)}`);
          } else {
            // console.log(`else here`);
            const myUserData = await fetchPlayerByAuthId(this.state.firebaseUser);
            if (myUserData) {
              // console.log(`myUserData: ${JSON.stringify(myUserData)}`);
              this.setState({ myUserData });
              newMyGameIds = myUserData.myGameIds;
              newMyGameIds.push(foundGame.id);
            } else {
              // No user found for this firebase uid
              console.log(`No user found for this firebase uid`);
              return;
            }
          }

          const postToUserUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/user/?userId=${this.state.myUserId}`;
          await fetch(postToUserUrl, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization':`Bearer ${bearerToken}`,
            },
            body: JSON.stringify({
              myGameIds: newMyGameIds,
            })
          })
          .then(async () => {
            const newMyUserData = this.state.myUserData;
            let newMyUserGameIds: string[] = [];
            if(newMyUserData) {
              if( newMyUserData.myGameIds.length > 0) {
                newMyUserGameIds = newMyUserData.myGameIds;
              }
              newMyUserGameIds.push(foundGame.id);
              newMyUserData.myGameIds = newMyUserGameIds;
              // console.log(`joinGameWithCode - new myUserData: ${JSON.stringify(newMyUserData)}`);
              this.setState({ 
                myUserData: newMyUserData,
                isSendingGameCode: false,
              });
              await this.fetchGames();
            } else {
              this.setState({ isSendingGameCode: false });
            }
          })
          .catch((error) => {
            this.setState({ 
              isSendingGameCode: false,
            })
          });

        })
        .catch((error) => {
          this.setState({ 
            isSendingGameCode: false,
          })
        });
      }

    })
    .catch((error) => {
      this.setState({ 
        isLoading: false,
      })
    });
  }

  private async fetchGames() {
    // console.log(`~~~ fetchGames`);
    if (this.state.myUserData) {
      // console.log(`this.state.myUserData = ${JSON.stringify(this.state.myUserData)}`);
      // Get White Elephant Games
      const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/myGamesByIds/`;
  
      const bearerToken = await this.state.firebaseUser?.getIdToken();
      // console.log(`preparing to fetch | ${JSON.stringify(this.state.myUserData.myGameIds)}`);
      await fetch(targetUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${bearerToken}`,
        },
        body: `{ "myGameIds": ${JSON.stringify(this.state.myUserData.myGameIds)} }`,
      })
      .then(async response => {
        const responseBody = await response.text();
        const { data } = JSON.parse(responseBody);
        // console.log(`data = ${JSON.stringify(data)}`);
        // console.log(`data.length = ${JSON.stringify(data.length)}`);
        // console.log(`~~ data.gameTime = ${new Date(data[0].gameTime.toDate()).toLocalestring()}`);
        const gameData: WhiteElephantGameData[] = [];
        data.forEach((game: any) => {
          // console.log(' ****' + convertDatastringToWhiteElephantGameData(JSON.stringify(game)));
          gameData.push(convertDatastringToWhiteElephantGameData(JSON.stringify(game)));
        });
        // console.log(`gameData = ${JSON.stringify(gameData)}`);
  
        this.setState({ 
          isLoading: false,
          myGames: gameData,
        }, () => {
          gameData.forEach(game => {
            const newGameIdToGame: {[key: string]: WhiteElephantGameData} = this.state.gameIdToGame;
            newGameIdToGame[game.id] = game;
            this.setState({ gameIdToGame: newGameIdToGame});
          });
        })
      })
      .catch((error) => {
        // console.log(`fetch error = ${JSON.stringify(error)}`);
        this.setState({ 
          isLoading: false,
        })
      });
    } else {
      this.setState({ 
        isLoading: false,
      });
    }

  }


}
WhiteElephantGame.contextType = AuthContext;