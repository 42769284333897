import firebase from "firebase";
import { ResponseStatus } from "../constants/dataConstants";
import { realtimeDb } from "../firebase";

// ----- User Status Functions -----
function getUserStatusDatabaseRef(userId: string) {
  return realtimeDb.ref('/status/' + userId);
}

export function connectToStatus(userId: string) {

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  const userStatusDatabaseRef = getUserStatusDatabaseRef(userId);

  // We'll create two constants which we will write to 
  // the Realtime database when this device is offline
  // or online.
  const isOfflineForDatabase = {
      state: 'offline',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  const isOnlineForDatabase = {
      state: 'online',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  // Create a reference to the special '.info/connected' path in 
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  realtimeDb.ref('.info/connected').on('value', function(snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
          return;
      };

      // If we are currently connected, then use the 'onDisconnect()' 
      // method to add a set which will only trigger once this 
      // client has disconnected by closing the app, 
      // losing internet, or any other means.
      userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect() 
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.set(isOnlineForDatabase);
      });
  });
}

export function checkPlayerStatus(playerId: string, statusCallback?: (playerId: string, playerState: string, last_changed?: number) => any) {

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  const userStatusDatabaseRef = getUserStatusDatabaseRef(playerId);

  userStatusDatabaseRef.on('value', (snapshot) => {
    const data: {[key: string]: any} = snapshot.val();
    if (statusCallback && data?.['state']) {
      statusCallback(playerId, data['state'], data['last_changed']);
    }
  });
}

export function disconnectFromStatus(userId: string) {
  const userStatusDatabaseRef = getUserStatusDatabaseRef(userId);
  userStatusDatabaseRef.off();
}
export function disconnectFromCheckPlayerStatus(playerId: string) {
  const userStatusDatabaseRef = getUserStatusDatabaseRef(playerId);
  userStatusDatabaseRef.off();
}


// ----- Game Status Functions -----
function getGameStatusDatabaseRef(gameId: string) {
  return realtimeDb.ref('/gameStatus/' + gameId);
}

export function checkGameStatus(gameId: string, statusCallback?: (gameStatus: string, last_changed?: number) => any) {
  // Create a reference to this game status node.
  // This is where we will store data about game state.
  const gameStatusDatabaseRef = getGameStatusDatabaseRef(gameId);

  gameStatusDatabaseRef.on('value', (snapshot) => {
    const data: {[key: string]: any} = snapshot.val();
    if (statusCallback && data?.['status']) {
      statusCallback(data['status'], data['last_changed']);
    }
  });
}

export function updateGameStatus(gameId: string, newGameStatus: string, updateCallback: (responseStatus: ResponseStatus) => any) {
  // Create a reference to this game status node.
  // This is where we will store data about game state.
  const gameStatusDatabaseRef = getGameStatusDatabaseRef(gameId);

  const newGameStatusValue = {
      status: newGameStatus,
      last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  gameStatusDatabaseRef.set(newGameStatusValue)
  .then(() => {
    // console.log('Synchronization succeeded');
    updateCallback(ResponseStatus.success);
  })
  .catch((error) => {
    // console.log('Synchronization failed');
    updateCallback(ResponseStatus.error);
  });
}
