
import React from "react";
import { Button, Modal } from "react-bootstrap";

interface BaseComponentState {
  shouldShow: boolean;
}

interface BaseComponentProps {
  shouldShow: boolean;
  title: string;
  bodyMessage: string;
  onClose: () => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class InfoModal<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      shouldShow: this.props.shouldShow,
    }
  }

  render() {
    return (
      <Modal show={this.state.shouldShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.bodyMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private handleClose = () => {
    this.setState({ shouldShow: false }, () => {
      this.props.onClose();
    });
  };
}