export enum WhiteElephantLocalStorageKey {
  currentGame = "ElephantGiftExchange_CurrentGame",
  myUserId = "ElephantGiftExchange_MyUserId",
  joinWithCode = "ElephantGiftExchange_JoinWithCode"
}

export enum ShoppingSites {
  amazon = "Amazon",
  etsy = "Etsy",
  target = "Target",
  other = "Other",
  in_person = "In-Person",
}

export interface GiftSelectionCandidate {
  id?: string,
  productName?: string,
  url?: string,
  description?: string,
  price?: number,
  shoppingSite?: ShoppingSites,
  userId?: string,
  gameId?: string,
  wrappedGiftId?: string[];
  giftImageIds?: string[],
  createdAt?: Date,
}

export interface GiftSelectionData {
  id: string,
  productName: string,
  url?: string,
  description?: string,
  price: number,
  shoppingSite: ShoppingSites,
  userId: string,
  gameId: string,
  wrappedGiftId?: string[];
  giftImageIds?: string[],
  createdAt: Date,
}

export function convertDatastringToGiftSelectionData(data: string) {
  const gift = JSON.parse(data);
  // console.log(`convert game: ${JSON.stringify(gift)}`);
  let thisCreatedAt;
  if (gift.createdAt._seconds) {
    thisCreatedAt = gift.createdAt._seconds * 1000;
  } else {
    thisCreatedAt = gift.createdAt;
  }
  return {
    id: gift.id,
    productName: gift.productName,
    url: gift.url,
    description: gift.description,
    price: gift.price,
    shoppingSite: gift.shoppingSite,
    userId: gift.userId,
    gameId: gift.gameId,
    createdAt: new Date(thisCreatedAt),
    giftImageIds: gift.giftImageIds,
    wrappedGiftId: gift.wrappedGiftId,
  } as GiftSelectionData
}

export function convertDataArraystringToGiftSelectionData(data: string) {
  // console.log(`convertDataArraystringToGiftSelectionData: ${JSON.stringify(data)}`);
  const gifts = JSON.parse(data).data;
  // console.log(`convert game: ${JSON.stringify(gifts)}`);
  return (
    gifts.map((gift: any) => {
      let thisCreatedAt;
      if (gift.createdAt._seconds) {
        thisCreatedAt = gift.createdAt._seconds * 1000;
      } else {
        thisCreatedAt = gift.createdAt;
      }
      return {
        id: gift.id,
        productName: gift.productName,
        url: gift.url,
        description: gift.description,
        price: gift.price,
        shoppingSite: gift.shoppingSite,
        userId: gift.userId,
        gameId: gift.gameId,
        createdAt: new Date(thisCreatedAt),
        giftImageIds: gift.giftImageIds,
        wrappedGiftId: gift.wrappedGiftId,
      } as GiftSelectionData
    })
  );
}

export interface UserData {
  id: string,
  email: string,
  name?: string,
  authenticatedUid?: string,
  address1?: string,
  address2?: string,
  city?: string,
  state?: string,
  zipcode?: number,
  myGameIds: string[],
  createdAt: Date,
}

export enum WhiteElephantGameStatus {
  not_started = "Not Started",
  game_room_open = "Game Room Open",
  started = "Started",
  finished = "Finished",
}

export enum WhiteElephantGameFormat {
  classic = "Classic",
  single_steal = "Single Steal",
}

export interface WhiteElephantGameData {
  id: string,
  gameName: string,
  hostId: string,
  gameTime: Date,
  players: string[],
  playerOrder?: string[],
  gifts?: string[],
  gameRules?: { [key: string]: any},
  gameCode: string,
  status: string,
  imageIds?: string[],
  createdAt: Date,
}

export function convertDatastringToWhiteElephantGameData(data: string) {
  const game = JSON.parse(data);
  // console.log(`convert game: ${JSON.stringify(game)}`);
  let thisGameTime, thisCreatedAt;
  if (game.gameTime._seconds) {
    thisGameTime = game.gameTime._seconds * 1000;
    thisCreatedAt = game.createdAt._seconds * 1000;
  } else {
    thisGameTime = game.gameTime;
    thisCreatedAt = game.createdAt;
  }
  return {
    id: game.id,
    gameName: game.gameName,
    hostId: game.hostId,
    gameTime: new Date(thisGameTime),
    players: game.players,
    playerOrder: game.playerOrder,
    gifts: game.gifts,
    gameRules: game.gameRules,
    gameCode: game.gameCode,
    status: game.status,
    imageIds: game.imageIds,
    createdAt: new Date(thisCreatedAt),
  } as WhiteElephantGameData
}

export enum WhiteElephantGameAction {
  take = "Take",
  open = "Open",
  keep = "Keep",
}

export interface WhiteElephantGameEventData {
  eventId: string,
  actionNum: number,
  gameId: string,
  round: number,
  nextActionPlayerId: string;
  canTakeBeNextAction: boolean;
  canOpenBeNextAction: boolean;
  canKeepBeNextAction: boolean;
  nextTakeableGiftIds: {[key: string]: string},
  giftIdToPlayerId: {[key: string]: string},
  playerId?: string,
  action?: string,
  giftIdTaken?: string,
  takenFromPlayerId?: string;
  createdAtUnix: number,
}

export const WhiteElephantGameEventCollection = "WhiteElephantGameEvent";

export interface MyActionSelection {
  lastGameEvent: WhiteElephantGameEventData,
  myUserId: string,
  myAction: string,
  currentGameGifts: GiftSelectionData[],
  giftIdTaken?: string,
  takenFromPlayerId?: string;
}

export interface ChatMessageData {
  id: string,
  gameId: string,
  playerId: string,
  content: string,
  createdAtUnix: number,
}

export const ChatMessageCollection = "ChatMessage";

export enum gifType {
  unwrap = "Unwrap",
  takeGift = "takeGift",
  keep = "keep",
}

export enum ResponseStatus {
  success = "Success",
  error = "Error",
}

