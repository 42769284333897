
import React from "react";
import { Button, Form, Modal, Spinner, Alert } from "react-bootstrap";
import { UserData } from "../constants/dataConstants";
import { fetchPlayerByAuthId } from "../utils/fetchUserUtils";
import './EditAccountModal.css';

interface BaseComponentState {
  shouldShow: boolean;
  userData: UserData;
  isSavingUserData: boolean;
  isLoadingData: boolean;
  shouldShowAlert: boolean;
  alertMessage: string;
  shouldShowIncompleteUserAlert: boolean;
}

interface BaseComponentProps {
  firebaseUser: any;
  shouldShow: boolean;
  userData: UserData;
  isUserIncomplete: boolean;
  onCloseCallback?: () => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class EditAccountModal<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      shouldShow: this.props.shouldShow,
      isSavingUserData: false,
      isLoadingData: true,
      shouldShowAlert: false,
      alertMessage: '',
      userData: this.props.userData,
      shouldShowIncompleteUserAlert: this.props.isUserIncomplete,
    }
  }

  async componentDidMount() {
    if(!this.props.userData) {
      await fetchPlayerByAuthId(this.props.firebaseUser)
      .then((myUserData) => {
        if(myUserData) {
          this.setState({isLoadingData: false, userData: myUserData});
        } else {
          this.setState({isLoadingData: false, shouldShow: false});
        }
      });
    } else {
      this.setState({isLoadingData: false});
    }
  }

  render() {
    const { email, name, address1, address2, city, state, zipcode } = this.state.userData;
    return (
      <Modal show={this.state.shouldShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.state.shouldShowIncompleteUserAlert &&
          <Alert variant="warning" onClose={() => this.setState({ shouldShowIncompleteUserAlert: false })} dismissible>
          <Alert.Heading>User Incomplete</Alert.Heading>
          <p>
            Please include all details so we can send the gift to the right place
          </p>
          </Alert>}
        <Form className="Form-EditAccount">
          <Form.Group controlId="formName">
            <Form.Label className="Form-Label">Name: </Form.Label>
            <Form.Control className="Form-Input" type="text" placeholder="Enter your name (also used for shipping purposes)" onChange={val => {
              const userData = this.state.userData!;
              userData.name = val.target.value;
              this.setState({ userData });
            }} value={name ?? ''} />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label className="Form-Label">Email: </Form.Label>
            <Form.Control className="Form-Input" type="text" placeholder="Enter your email" onChange={val => {
              const userData = this.state.userData!;
              userData.email = val.target.value;
              this.setState({ userData });
            }} value={email ?? ''} />
          </Form.Group>
          <Form.Group controlId="formAddress1">
            <Form.Label className="Form-Label">Address: </Form.Label>
            <Form.Control className="Form-Input" type="text" placeholder="(e.g. 123 Main Street)" onChange={val => {
              const userData = this.state.userData!;
              userData.address1 = val.target.value;
              this.setState({ userData });
            }} value={address1 ?? ''} />
          </Form.Group>
          <Form.Group controlId="formAddress2">
            <Form.Control className="Form-Input" type="text" placeholder="(e.g. Apt 456)" onChange={val => {
              const userData = this.state.userData!;
              userData.address2 = val.target.value;
              this.setState({ userData });
            }} value={address2 ?? ''} />
          </Form.Group>
          <Form.Group controlId="formCity">
            <Form.Label className="Form-Label">City: </Form.Label>
            <Form.Control className="Form-Input" type="text" placeholder="(e.g. Chicago)" onChange={val => {
              const userData = this.state.userData!;
              userData.city = val.target.value;
              this.setState({ userData });
            }} value={city ?? ''} />
          </Form.Group>
          <Form.Group controlId="formState">
            <Form.Label className="Form-Label">State: </Form.Label>
            <Form.Control className="Form-Input" type="text" placeholder="(e.g. IL)" onChange={val => {
              const userData = this.state.userData!;
              userData.state = val.target.value;
              this.setState({ userData });
            }} value={state ?? ''} />
          </Form.Group>
          <Form.Group controlId="formZipcode">
            <Form.Label className="Form-Label">Zipcode: </Form.Label>
            <Form.Control className="Form-Input" type="number" placeholder="(e.g. 12345)" onChange={val => {
              const userData = this.state.userData!;
              userData.zipcode = Number(val.target.value);
              this.setState({ userData });
            }} value={zipcode ?? ''} />
          </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            if (this.state.userData) {
              this.setState({isSavingUserData: true});
              this.saveUserInfo(this.state.userData!);
            }
          }} disabled={this.state.isSavingUserData}>
          {this.state.isSavingUserData && <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />}
            Save
          </Button>
          <Button variant="secondary" onClick={this.handleClose} disabled={this.state.isSavingUserData}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private handleClose = () => {
    this.setState({ shouldShow: false }, () => {
      if (this.props.onCloseCallback)
        this.props.onCloseCallback();
    });
  };

  private async saveUserInfo(userData: UserData) {
    const postToUserUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/user/?userId=${userData.id}`;
    const bearerToken = await this.props.firebaseUser.getIdToken();
    await fetch(postToUserUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
      body: JSON.stringify(userData)
    })
    .then(() => {
      this.setState({ 
        isSavingUserData: false,
      });
    })
    .catch((error) => {
      this.setState({ 
        isSavingUserData: false,
      })
    });
  }
}