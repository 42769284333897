export interface RetryResponse<T> {
  isSuccessful: boolean;
  result?: T;
}

export function requestWithRetry<T>(func: () => T, maxRetryCount: number = 3): RetryResponse<T> {
  let attempt = 0;
  let result: T | undefined;
  while (attempt < maxRetryCount && !result) {
    result = func();
    attempt++;
  }
  if (result) {
    const resultAsType = result as T;
    return { isSuccessful: true, result: resultAsType };
  } else {
    return { isSuccessful: false };
  }
}

export interface AsyncFunction<T> {
  func: Promise<T>
}

export async function awaitAsyncRequestWithRetry<T>(asyncFunc: AsyncFunction<T>, maxRetryCount: number = 3): Promise<RetryResponse<T>> {
  let attempt = 0;
  let result: T | undefined;
  while (attempt < maxRetryCount && !result) {
    result = (await asyncFunc.func);
    attempt++;
  }
  if (result) {
    const resultAsType = result as T;
    return { isSuccessful: true, result: resultAsType };
  } else {
    return { isSuccessful: false };
  }
}
