
import React, { Component, ReactNode } from 'react';
import { auth } from '../firebase';

const defaultAuthContext = {
  isUserSignedIn: false,
  currentUser: {},
};

export const AuthContext = React.createContext(defaultAuthContext);

type AuthProviderProps = {
    children: ReactNode,
};

type AuthProviderState = {
    isUserSignedIn: boolean,
    currentUser: any,
};

// Create an exportable consumer that can be injected into components
export const AuthContextConsumer = AuthContext.Consumer

class AuthProvider extends Component<AuthProviderProps, AuthProviderState> {
  state = defaultAuthContext;

  private unsubscribeFromAuth: any = null;
  
  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(user => this.setState({
          isUserSignedIn: !!user,
          currentUser: user,
      }));
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }


  render(): ReactNode {
    const {isUserSignedIn, currentUser} = this.state;
    return (
      <AuthContext.Provider value={{isUserSignedIn, currentUser}}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;