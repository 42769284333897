
import React from "react";

import './MultipleColumnView.css';

interface BaseComponentProps {
  renderColumns: any[];
}

export type ComponentState<ChildState = {}> = ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class MultipleColumnView<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {

  render() {
    return (
      <div className="Full-Size-Screen">
        {this.props.renderColumns.map((column, i) => {
          return (
          <div key={i} className="One-Column-View">{column}</div>
          );
        })}
      </div>
    );
  }
}