import React from 'react';
import { Button, Spinner, Form, InputGroup, Alert, ProgressBar } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import moment from 'moment-timezone';
import firebase from 'firebase/app';
import * as uuid from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';


import './GiftPickerView.css';
import { GiftSelectionCandidate, GiftSelectionData, ShoppingSites, WhiteElephantGameData } from '../constants/dataConstants';
import { storageRef } from '../firebase';
import { isOneOfDefaultGiftBoxes, randomGiftBoxImageId } from '../utils/fetchGiftSelectionUtils';
import giftBox1 from '../assets/default_box/gift-box-1.jpg';
import giftBox2 from '../assets/default_box/gift-box-2.jpg';
import giftBox3 from '../assets/default_box/gift-box-3.jpg';
import giftBox4 from '../assets/default_box/gift-box-4.jpg';
import searchIcon from '../assets/icons/search-white.png';
import GiftFinderModal from './GiftFinderModal';
import searchAmazon, { SearchData } from '../amazon-search/search';
import { generateMyAmazonUrl, isValidHttpUrl } from '../utils/urlUtils';

interface BaseComponentState {
  isSavingGift: boolean;
  isLoadingData: boolean;
  shouldShowAlert: boolean;
  alertMessage: string;
  shouldShowSuccessAlert: boolean;

  isGiftFinderEnabled: boolean;
  shouldShowGiftFinderModal: boolean;

  imagesUploaded: number;
  numOfImagesToUpload: number;
  imageUploadPercentage: number;

  myGiftSelectionCandidate: GiftSelectionCandidate;
  urlToFileGiftImagesToUpload: {[url: string]: File};
  isGiftImageIdSelected: {[imageId: string]: boolean};
  urlToFileWrappedGiftImagesToUpload: {[url: string]: File};
  isWrappedImageIdSelected: {[imageId: string]: boolean};
  myGiftSelection?: GiftSelectionData;
}

interface BaseComponentProps {
  firebaseUser: any;
  myUserId: string;
  currentGame: WhiteElephantGameData;
  myGiftSelection?: GiftSelectionData;
  onSaveCallback: (giftSelection: GiftSelectionData) => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class GiftPickerView<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      isSavingGift: false,
      isLoadingData: true,
      shouldShowAlert: false,
      shouldShowSuccessAlert: false,
      shouldShowGiftFinderModal: false,
      alertMessage: '',
      imagesUploaded: 0,
      numOfImagesToUpload: 0,
      imageUploadPercentage: 0,

      myGiftSelectionCandidate: this.props.myGiftSelection ? this.props.myGiftSelection as GiftSelectionCandidate : {},
      urlToFileGiftImagesToUpload: {},
      isGiftImageIdSelected: {},
      urlToFileWrappedGiftImagesToUpload: {},
      isWrappedImageIdSelected: {},
      myGiftSelection: this.props.myGiftSelection,
    }
    this.onDropGiftImage = this.onDropGiftImage.bind(this);
    this.onDropWrappedGiftImage = this.onDropWrappedGiftImage.bind(this);
  }

  componentDidMount() {
    const isGiftImageIdSelected: {[imageId: string]: boolean} = {};
    this.state.myGiftSelection?.giftImageIds?.forEach((imageId) => {
      isGiftImageIdSelected[imageId] = true;
    });
    const isWrappedImageIdSelected: {[imageId: string]: boolean} = {};
    this.state.myGiftSelection?.wrappedGiftId?.forEach((imageId) => {
      isWrappedImageIdSelected[imageId] = true;
    });
    this.setState({ isGiftImageIdSelected, isWrappedImageIdSelected });
  }
  
  render() {
    const timeZonestring = this.props.currentGame.gameTime.toString().match(/\(([A-Za-z\s].*)\)/)![1];
    return (
      <div className="GiftPicker-FullWidth">
        {this.state.shouldShowGiftFinderModal &&
          <GiftFinderModal shouldShow={true} onGiftSelectionCallback={this.onGiftSelectionCallback} onCloseCallback={() => { this.setState({ shouldShowGiftFinderModal: false })}} />}
        <Form>
          <h3>
            {`Your gift for the White Elephant Game: ${this.props.currentGame.gameName}`}
          </h3> 
          <Form.Group controlId="formWrapedGiftImageLabel" className="GiftPicker-FullWidth">
          {this.state.isGiftFinderEnabled && 
          <div className={"GiftPicker-FullWidth Center-Horizontal"}>
            <div className={"FullWidth ColumnContainer"}>
              <div className={"HalfWidth-Column Center-Middle"}>
                <Button variant='primary' className={"Form-Button"} onClick={() => {
                  this.setState({ shouldShowGiftFinderModal: true });
                }}>
                  <img src={searchIcon} alt="searchIcon" />
                  Find a gift
                </Button>
              </div>
              <div className={"HalfWidth-Column Center-Middle"}>
                <Button variant='primary' className={"Form-Button MarginLeft-2"} onClick={() => {
                  this.setState({ shouldShowGiftFinderModal: true });
                }}>
                  Manual add
                </Button>
              </div>
            </div>
          </div>}
          {this.props.currentGame.gameRules?.['MinPrice'] && 
          <Form.Label className={"GiftPicker-FullWidth Center-Horizontal"}>{`(Suggested gift value: $${this.props.currentGame.gameRules?.['MinPrice']}${this.props.currentGame.gameRules?.['MaxPrice'] ? ' - $' + this.props.currentGame.gameRules?.['MaxPrice'] : ''})`}</Form.Label>}

          {this.renderManualGiftFormInputs()}
          </Form.Group>
          <Form.Group controlId="formWrapedGiftImages" className="GiftPicker-FullWidth">
            <div className={"PaddingBottom-2"}>Choose a gift wrapping or upload your own gift wrapping</div>
            <div className={"GiftPicker-FullWidth"}>
              {this.renderImageThumbnailSelector(this.props.myGiftSelection?.wrappedGiftId ?? [], this.state.isWrappedImageIdSelected, 'wrapped gifts', this.handleWrappedImageSelectClick)}
            </div>
          </Form.Group>
          <Form.Group controlId="formImageUploadGift">
            <ImageUploader
              withIcon={true}
              buttonText="Add your own wrapped gift"
              onChange={this.onDropWrappedGiftImage}
              imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
              withPreview={true}
            />
          </Form.Group>
          <Button variant='outline-primary' className="Form-Button" onClick={async () => {
            if(this.validateGiftSelection()) {
              await this.updateGiftSelection().then(() => {
                this.setState({ shouldShowAlert: false });
              });
            } else {
              this.setState({ shouldShowSuccessAlert: false, shouldShowAlert: true });
            }
          }} disabled={this.state.isSavingGift || (this.state.imagesUploaded < this.state.numOfImagesToUpload)}>
            {this.state.isSavingGift && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
            Save Gift
          </Button>
            {this.state.imagesUploaded < this.state.numOfImagesToUpload &&
              <div>
                <div>
                  Uploading image {this.state.imagesUploaded} of {this.state.numOfImagesToUpload}...
                </div>
                <div>
                  <ProgressBar now={this.state.imageUploadPercentage} />
                </div>
              </div>}
          {this.state.shouldShowAlert && <Alert variant="danger" onClose={() => this.setState({ shouldShowAlert: false })} dismissible>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              {this.state.alertMessage}
            </p>
          </Alert>}
          {this.state.shouldShowSuccessAlert && 
          <Alert variant="success" onClose={() => this.setState({ shouldShowSuccessAlert: false })} dismissible>
            <Alert.Heading>Save Successful</Alert.Heading>
          </Alert>}
          <h4>
            {`Game scheduled to begin on ${moment(this.props.currentGame.gameTime).format('dddd MM/DD/yyyy[ at ]hh:mm a')} ${`(${timeZonestring})`}`}
          </h4>
        </Form>
      </div>
    );
  }

  checkAmazonScreenScraper = async () => {
    await searchAmazon('gift', {includeSponsoredResults: false}).then((data: SearchData) => {
      if (data?.searchResults?.length > 1) {
        this.setState({ isGiftFinderEnabled: true });
      }
    });
  }

  private onGiftSelectionCallback = (giftSelectionCandidate: GiftSelectionCandidate) => {
    giftSelectionCandidate.userId = this.props.myUserId;
    giftSelectionCandidate.gameId = this.props.currentGame.id;
    this.setState({ shouldShowGiftFinderModal: false, myGiftSelectionCandidate: giftSelectionCandidate }, async () => {
      if(this.validateGiftSelection()) {
        await this.updateGiftSelection();
      }
    });
  }

  renderManualGiftFormInputs() {
    return (
      <div className={"Full-Width"}>
      <Form.Group controlId="formProductName">
        <Form.Label className="FloatLeft">Product Name: </Form.Label>
        <Form.Control type="text" placeholder="Enter your product name" onChange={val => {
          const newGiftSelectionCandidate = this.state.myGiftSelectionCandidate;
          newGiftSelectionCandidate.productName = val.target.value;
          this.setState({ myGiftSelectionCandidate: newGiftSelectionCandidate });
        }}
        value={this.state.myGiftSelectionCandidate.productName ? '' + this.state.myGiftSelectionCandidate.productName : ''} />
      </Form.Group>
      <Form.Group controlId="formProductShoppingSite" className="FullWidth">
      <Form.Label className="FloatLeft">Shopping Website</Form.Label>
        <Form.Control as="select" className="FullWidth" onChange={val => {
          const newGiftSelectionCandidate = this.state.myGiftSelectionCandidate;
          if(val.target.value === 'Amazon')
            newGiftSelectionCandidate.shoppingSite = ShoppingSites.amazon;
          else if(val.target.value === 'Etsy')
            newGiftSelectionCandidate.shoppingSite = ShoppingSites.etsy;
          else if(val.target.value === 'Target')
            newGiftSelectionCandidate.shoppingSite = ShoppingSites.target;
          else if(val.target.value === 'Other Website')
            newGiftSelectionCandidate.shoppingSite = ShoppingSites.other;
          else if(val.target.value === 'In-Person')
            newGiftSelectionCandidate.shoppingSite = ShoppingSites.in_person;
          this.setState({ myGiftSelectionCandidate: newGiftSelectionCandidate });
        }}>
          <option>Amazon</option>
          {/* <option>Etsy</option>
          <option>Target</option> */}
          <option>Other Website</option>
          <option>In-Person</option>
        </Form.Control>
      </Form.Group>
      {this.state.myGiftSelectionCandidate.shoppingSite !== ShoppingSites.in_person &&
        <Form.Group controlId="formProductUrl">
        <Form.Label className="FloatLeft">Product Url: </Form.Label>
        <Form.Control type="text" placeholder="Enter your product url" onChange={val => {
          const newGiftSelectionCandidate = this.state.myGiftSelectionCandidate;
          newGiftSelectionCandidate.url = val.target.value;
          this.setState({ myGiftSelectionCandidate: newGiftSelectionCandidate });
        }}
        value={this.state.myGiftSelectionCandidate.url ? '' + this.state.myGiftSelectionCandidate.url : ''} />
      </Form.Group>}
      <Form.Group controlId="formProductDescription">
        <Form.Label className="FloatLeft">Product Description: </Form.Label>
        <textarea rows={3} placeholder="Enter your product description" onChange={val => {
          const newGiftSelectionCandidate = this.state.myGiftSelectionCandidate;
          newGiftSelectionCandidate.description = val.target.value;
          this.setState({ myGiftSelectionCandidate: newGiftSelectionCandidate });
        }}
        value={this.state.myGiftSelectionCandidate.description ? '' + this.state.myGiftSelectionCandidate.description : ''}
        className={"FullWidth"} />
      </Form.Group>
      <Form.Group controlId="formProductPrice">
        <Form.Label className="FloatLeft">Product Price / Value: </Form.Label>
        <InputGroup className={"Form-Money-ProductPrice FullWidth"}>
          <InputGroup.Prepend>
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup.Prepend>
          <input aria-label="Amount" type="number" min="0" onChange={val => {
            const newGiftSelectionCandidate = this.state.myGiftSelectionCandidate;
            newGiftSelectionCandidate.price = val.target.valueAsNumber;
            this.setState({ myGiftSelectionCandidate: newGiftSelectionCandidate });
          }}
          value={this.state.myGiftSelectionCandidate.price ? this.state.myGiftSelectionCandidate.price : ''} />
        </InputGroup>
      </Form.Group>
      <Form.Row id="formGiftImageLabel" className={"FullWidth PaddingLeft-2"}>
      <Form.Label className="FloatLeft">Upload a gift image</Form.Label>
      </Form.Row>
      <Form.Group controlId={"formImages"} className="FullWidth">
        {this.props.myGiftSelection?.giftImageIds && (
          this.renderImageThumbnailSelector(this.props.myGiftSelection.giftImageIds, this.state.isGiftImageIdSelected, 'giftImages', this.handleGiftImageSelectClick)
        )}
      </Form.Group>
      <Form.Group controlId="formImageUploadGift">
        <ImageUploader
          withIcon={true}
          buttonText="Add gift images"
          onChange={this.onDropGiftImage}
          imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
          withPreview={true}
        />
      </Form.Group>
      </div>
    );
  }

  renderImageThumbnailSelector(imageIds: string[], isImageIdSelected: {[imageId: string]: boolean}, categoryOfImages: string, handleImageSelection: (imageId: string) => void) {
    this.downloadImages(imageIds);
    if (categoryOfImages === 'wrapped gifts') {
      imageIds = imageIds.filter((imageId) => !isOneOfDefaultGiftBoxes(imageId));
      imageIds = [...imageIds, 'giftBox1', 'giftBox2', 'giftBox3', 'giftBox4'];
    }
    return (
      <div className="FullWidth">
        <div className="ScrollX">
        <div className="imageDiv">
        {imageIds.map((imageId, i) => {
          let classNameValue = "ThumbnailSize";
          if(isImageIdSelected[imageId]) {
            classNameValue += " BorderBlue"
          }
          if(isOneOfDefaultGiftBoxes(imageId)) {
            return (
              <img id={'default-'+imageId} key={'default-'+imageId} alt={`${categoryOfImages} ${i}`} className={classNameValue} src={this.getDefaultImageSrc(imageId)} onClick={() => {handleImageSelection(imageId)}} />
            );
          } else {
            return (
              <img id={imageId} key={imageId} alt={`${categoryOfImages} ${i}`} className={classNameValue} onClick={() => {handleImageSelection(imageId)}} />
            );
          }
        })}
        </div>
        </div>
      </div>
    );
  }
  handleGiftImageSelectClick = (imageId: string) => {
    const isImageIdSelected: {[imageId: string]: boolean} = this.state.isGiftImageIdSelected;
    isImageIdSelected[imageId] = !!!this.state.isGiftImageIdSelected[imageId];
    this.setState({ isGiftImageIdSelected: isImageIdSelected });
  }
  handleWrappedImageSelectClick = (imageId: string) => {
    const isImageIdSelected: {[imageId: string]: boolean} = this.state.isWrappedImageIdSelected;
    isImageIdSelected[imageId] = !!!this.state.isWrappedImageIdSelected[imageId];
    this.setState({ isWrappedImageIdSelected: isImageIdSelected });
  }
  private getDefaultImageSrc(defaultImageId: string) {
    if (defaultImageId === "giftBox1")
      return giftBox1;
    else if (defaultImageId === "giftBox2")
      return giftBox2;
    else if (defaultImageId === "giftBox3")
      return giftBox3;
    else
      return giftBox4;
  }

  renderImageThumbnails(imageIds: string[], categoryOfImages: string) {
    this.downloadImages(imageIds);
    return (
      <div className="FullWidth">
        <div className="ScrollX">
        <div className="imageDiv">
        {imageIds.map((imageId, i) => {
          return (
            <img id={imageId} key={imageId} alt={`${categoryOfImages} ${i}`} className={"ThumbnailSize"} />
          );
        })}
        </div>
        </div>
      </div>
    );
  }

  downloadImages(imageIds: string[]) {
    imageIds.forEach((imageId: string) => {
      if(!isOneOfDefaultGiftBoxes(imageId) && imageId.indexOf('m.media-amazon.com') < 0) {
        const imageRef = storageRef.child(imageId);
        imageRef.getDownloadURL().then((url) => {
          // inserted into an <img> element:
          const img = document.getElementById(imageId) as HTMLImageElement;
          if (img) {
            img.src = url;
          }
        });
      }
    });
  }

  onDropGiftImage(pictureFiles: File[], pictureDataURLs: string[]) {
    const urlToFileImagesToUpload: {[url: string]: File} = {};
    pictureDataURLs.forEach((url, i) => {
      urlToFileImagesToUpload[url] = pictureFiles[i];
    });
    this.setState({
      urlToFileGiftImagesToUpload: urlToFileImagesToUpload
    });
  }

  onDropWrappedGiftImage(pictureFiles: File[], pictureDataURLs: string[]) {
    const urlToFileWrappedGiftImagesToUpload: {[url: string]: File} = {};
    pictureDataURLs.forEach((url, i) => {
      urlToFileWrappedGiftImagesToUpload[url] = pictureFiles[i];
    });
    this.setState({
      urlToFileWrappedGiftImagesToUpload
    });
  }

    // Helper functions for Gift Selections
    private validateGiftSelection() {
      let alertMessage = '';
      if (!this.state.myGiftSelectionCandidate.productName) {
        alertMessage += 'Product Name missing. '
      } else if (this.state.myGiftSelectionCandidate.productName.length < 3) {
        alertMessage += 'Product Name is too short. Must be at least 3 characters.'
      }
      if (!this.state.myGiftSelectionCandidate.url) {
        alertMessage += 'Product Url missing. '
      } else if (!isValidHttpUrl(this.state.myGiftSelectionCandidate.url)) {
        alertMessage += 'Invalid Url. '
      }
      if (!this.state.myGiftSelectionCandidate.price) {
        alertMessage += 'Product Price missing. '
      }
      // Check that an image was provided
      if ( !((this.state.myGiftSelectionCandidate.giftImageIds && this.state.myGiftSelectionCandidate.giftImageIds?.length > 0) 
        || (Object.keys(this.state.urlToFileGiftImagesToUpload).length > 0)) ) {
          alertMessage += 'Please add a gift image. '
      }
      if (alertMessage.length > 0) {
        this.setState({ alertMessage });
        return false;
      }
      return true;
    }
    private async updateGiftSelection() {
      const giftImageUrls = Object.keys(this.state.urlToFileGiftImagesToUpload);
      const wrappedGiftImageUrls = Object.keys(this.state.urlToFileWrappedGiftImagesToUpload);
      this.setState({ 
        isSavingGift: true,
        imagesUploaded: 0,
        numOfImagesToUpload: giftImageUrls.length + wrappedGiftImageUrls.length,
      })
      // Save Images
      const newGiftImageIds: string[] = giftImageUrls.map((url) => {
        return this.uploadImage(this.state.urlToFileGiftImagesToUpload[url], url);
      });
      const newWrappedGiftImageIds: string[] = wrappedGiftImageUrls.map((url) => {
        return this.uploadImage(this.state.urlToFileWrappedGiftImagesToUpload[url], url);
      });

      const giftImageIds: string[] = [];
      const isGiftImageIdSelected: {[imageId: string]: boolean} = this.state.isGiftImageIdSelected;
      const selectedGifts: string[] = Object.keys(isGiftImageIdSelected);
      selectedGifts.forEach((imageId) => {
        if(!!isGiftImageIdSelected[imageId]) {
          giftImageIds.push(imageId);
        }
      });
      // TODO: Check that images are finished uploading before adding them to array
      giftImageIds.push(...newGiftImageIds);
      newGiftImageIds.forEach((imageId) => isGiftImageIdSelected[imageId] = true);

      let wrappedGiftId: string[] = [];
      const isWrappedImageIdSelected: {[imageId: string]: boolean} = this.state.isWrappedImageIdSelected;
      const selectedWrappedGifts: string[] = Object.keys(isWrappedImageIdSelected);
      if(selectedWrappedGifts.length === 0 && newWrappedGiftImageIds.length === 0) {
        wrappedGiftId = [randomGiftBoxImageId()];
      } else {
        selectedWrappedGifts.forEach((imageId) => {
          if(!!isWrappedImageIdSelected[imageId]) {
            wrappedGiftId.push(imageId);
          }
        });
      }
      // TODO: Check that images are finished uploading before adding them to array
      wrappedGiftId.push(...newWrappedGiftImageIds);
      newWrappedGiftImageIds.forEach((imageId) => isWrappedImageIdSelected[imageId] = true);
      let productUrl: string = this.state.myGiftSelectionCandidate.url!;
      if(this.state.myGiftSelectionCandidate.shoppingSite === ShoppingSites.amazon || productUrl.indexOf('amazon.com') >= 0) {
        productUrl = generateMyAmazonUrl(productUrl);
      }

      // Convert candidate to GiftSelection type
      const giftSelection: GiftSelectionData = {
        id: this.state.myGiftSelectionCandidate.id ?? uuid.v4(),
        productName: this.state.myGiftSelectionCandidate.productName!,
        url: productUrl,
        price: this.state.myGiftSelectionCandidate.price!,
        shoppingSite: this.state.myGiftSelectionCandidate.shoppingSite ?? ShoppingSites.other,
        userId: this.props.myUserId,
        gameId: this.props.currentGame.id,
        giftImageIds,
        wrappedGiftId,
        createdAt: new Date(),
      };
      if (this.state.myGiftSelectionCandidate.description) {
        giftSelection.description = this.state.myGiftSelectionCandidate.description;
      }
      const fetchMethod = 'POST';
      const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/giftSelection/?giftSelectionId=${giftSelection.id}`;
  
      const bearerToken = await this.props.firebaseUser.getIdToken();
      fetch(targetUrl, {
        method: fetchMethod,
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${bearerToken}`,
        },
        body: JSON.stringify(giftSelection),
      })
      .then(async () => {
        // const responseBody = await response.text();
        // console.log(`updateGiftSelection responseBody: ${responseBody}`);
        const myGiftSelectionCandidate = giftSelection as GiftSelectionCandidate;
        this.setState({ 
          isSavingGift: false,
          myGiftSelectionCandidate,
          myGiftSelection: giftSelection,
          urlToFileGiftImagesToUpload: {},
          urlToFileWrappedGiftImagesToUpload: {},
          isWrappedImageIdSelected,
          shouldShowSuccessAlert: (this.state.numOfImagesToUpload === this.state.imagesUploaded),
        }, () => {
          if (this.state.imagesUploaded === this.state.numOfImagesToUpload)
            this.props.onSaveCallback(giftSelection);
        });
      })
      .catch((error) => {
        this.setState({ 
          isSavingGift: false,
        });
      });
    }

    // Image Helper Functions
    private uploadImage(pictureFile: File, pictureDataURL: string) {
      // Create reference to image
      const myGiftCollection = 'GiftSelectionImage/' + this.props.myUserId;
      // Parse out relevant details from pictureDataURL
      // data:image/jpeg;name=modern_house.jpg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gIcSUNDX1BST0...
      const splitBySemiColon = pictureDataURL.split(';');
      const dataType = splitBySemiColon[0].split(':')[1];
      const fileName = splitBySemiColon[1].split('=')[1];
      
      const imageRef = storageRef.child(myGiftCollection).child(fileName);
      const uploadTask = imageRef.put(pictureFile, { contentType: dataType });

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on('state_changed', (snapshot) =>{
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        this.setState({ imageUploadPercentage: progress });
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function(error) {
        // Handle unsuccessful uploads
      }, () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL()
        .then((downloadURL) => {
          console.log('File available at', downloadURL);
          const imagesUploaded = this.state.imagesUploaded + 1;
          if (imagesUploaded === this.state.numOfImagesToUpload && this.state.myGiftSelection) {
            // All images uploaded successfully
            this.setState({shouldShowSuccessAlert: true});
            this.props.onSaveCallback(this.state.myGiftSelection!);
          }
    
          this.setState({ imagesUploaded });
        });
      });
      return myGiftCollection + '/' + fileName;
    }
}