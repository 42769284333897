import * as uuid from 'uuid';
import { ChatMessageCollection, ChatMessageData } from '../constants/dataConstants';
import { realtimeDb } from '../firebase';
import { convertDateToUnix } from './whiteElephantGameEventUtils';


export async function downloadChatMessagesOnce(gameId: string, callback: (chats: ChatMessageData[]) => any, createdAtOfLastDownloadedEvent?: number) {
  // Get game events from game
  const collectionPathName = `${ChatMessageCollection}/${gameId}`;

  let ref = realtimeDb.ref(collectionPathName);

  if (createdAtOfLastDownloadedEvent) {
    ref.orderByChild('createdAtUnix').endAt(createdAtOfLastDownloadedEvent)
    .once('value', (snapshot) => {
      handleChatMessageDownloadSnapshot(snapshot, callback)
    });
  } else {
    ref.orderByChild('createdAtUnix').limitToLast(15).once('value', (snapshot) => {
      handleChatMessageDownloadSnapshot(snapshot, callback)
    });
  }
}
export function downloadNewChatMessages(gameId: string, callback: (chats: ChatMessageData[]) => any, createdAtOfNewestDownloadedEvent?: number) {
  // Get game events from game
  const collectionPathName = `${ChatMessageCollection}/${gameId}`;

  let ref = realtimeDb.ref(collectionPathName);

  ref.orderByChild('createdAtUnix').limitToLast(1).startAt(createdAtOfNewestDownloadedEvent ?? null)
  .on('value', (snapshot) => {
    handleChatMessageDownloadSnapshot(snapshot, callback)
  });
}

function handleChatMessageDownloadSnapshot(snapshot: any, callback: (chats: ChatMessageData[]) => any) {
  const data: {[key: string]: any} = snapshot.val();
  // console.log(`gameEvents -  data  ---> ${JSON.stringify(data)}`);
  const chats: ChatMessageData[] = [];
  if (data) {
    Object.keys(data).forEach(key => {
      chats.push(data[key] as ChatMessageData);
    });
  }
  callback(chats);
}

export function sendChatMessage(gameId: string, playerId: string, content: string, callbackOnSuccessfulSet: (ChatMessageSent: ChatMessageData) => any) {
  // console.log(`sendChatMessage  --> currentUser: ${JSON.stringify(playerId)}`);
  // console.log(`sendChatMessage  --> content: ${JSON.stringify(content)}`);

  let ChatMessageToSend: ChatMessageData = {
    id: uuid.v4(),
    gameId,
    playerId,
    content,
    createdAtUnix: convertDateToUnix(new Date()),
  }

  realtimeDb.ref(ChatMessageCollection + '/' + gameId + '/' + ChatMessageToSend.id).transaction(function(currentData) {
    if (currentData === null) {
      return ChatMessageToSend;
    } else {
      console.log(`EventId ${ChatMessageToSend.id} already exists.`);
      return; // Abort the transaction.
    }
  }, function(error, committed, snapshot) {
    if (error) {
      console.log('Transaction failed abnormally!', error);
    } else if (!committed) {
      console.log(`We aborted the transaction (because chat id ${ChatMessageToSend.id} already exists).`);
    } else {
      // console.log(`EventId ${ChatMessageToSend.id} added!`);
      callbackOnSuccessfulSet(ChatMessageToSend);
    }
    // if (snapshot) {
    //   console.log("Event's data: ", snapshot.val());
    // }
  });
}
