import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, Spinner, Carousel, Modal, DropdownButton, Dropdown, CarouselItem, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import * as uuid from 'uuid';
import { Animated } from "react-animated-css";
import ReactCardFlip from 'react-card-flip';
import 'bootstrap/dist/css/bootstrap.min.css';

import './PlayWhiteElephant.css';
import { convertDatastringToWhiteElephantGameData, GiftSelectionData, gifType, MyActionSelection, UserData, WhiteElephantGameAction, WhiteElephantGameData, WhiteElephantGameEventCollection, WhiteElephantGameEventData, WhiteElephantGameStatus, WhiteElephantLocalStorageKey } from '../constants/dataConstants';
import { auth, realtimeDb,  } from '../firebase';
import { WHITE_ELEPHANT_GAME } from '../constants/routes';
import logoRed from '../assets/logo-red.png';
import leftArrow from '../assets/icons/chevron-left-black-18dp.png'
import giftBox1 from '../assets/default_box/gift-box-1.jpg';
import giftBox2 from '../assets/default_box/gift-box-2.jpg';
import menuBlack from '../assets/icons/menu-black.png';
import whiteChatIcon from '../assets/icons/chat-white.png';
import whiteChatRedCircleIcon from '../assets/icons/white-chat-red-circle.png';
import whiteCancelIcon from '../assets/icons/cancel-white.png';
import { fetchGiftsByGameId } from '../utils/fetchGiftSelectionUtils';
import { downloadWhiteElephantGameEvents, isNotFinalAction, resetWhiteElephantGameEvents } from '../utils/whiteElephantGameEventUtils';
import ImageDownloadComponent from '../utils/imageDownloadComponent';
import { fetchPlayerByAuthId, fetchPlayerById } from '../utils/fetchUserUtils';
import ChatComponent from '../chat/ChatComponent';
import MultipleColumnView from '../layout-components/MultipleColumnView';
import GiftPickerView from '../gifts/GiftPickerView';
import YourTurnModal from './YourTurnModal';
import ActionGifphyModal from './ActionGiphyModal';
import InfoModal from '../modals/InfoModal';
import HostEditGameRulesView from '../hosting/HostEditGameRulesView';
import GamePlayerStatusView from './GamePlayerStatusView';
import GameRoomView, { TabTypes } from './GameRoomView';
import { updateGameStatus } from '../utils/StatusUtils';
import { generateMyAmazonUrl } from '../utils/urlUtils';
import RemoveGameModal from './RemoveGameModal';

interface BaseComponentState {
  firebaseUser?: any;
  myUserId?: string;
  redirectHome: boolean;
  myGiftSelection?: GiftSelectionData;
  myTurnGiftSelection?: GiftSelectionData;

  currentGame: WhiteElephantGameData;
  playerIdToPlayer: {[key: string]: UserData};
  currentGameGifts: GiftSelectionData[];
  giftIdToGift?: {[key: string]: GiftSelectionData};
  giftIdToPlayerId?: {[key: string]: string};

  lastGameEvent?: WhiteElephantGameEventData;
  previousGameEvent?: WhiteElephantGameEventData;
  hasNewEventOccured: boolean;

  isChatBoxToggled: boolean;
  shouldShowChatBox: boolean;
  shouldToggleMessageReceived: boolean;

  isSending: boolean;

  shouldShowSelectModal: boolean;
  isSendingAction: boolean;

  showInfoModal: boolean;
  modalTitle: string;
  modalBody: string;
  
  isLoading: boolean;
  isLoadingNameData: boolean;
  isLoadingGiftData: boolean;
  isLoadingGameData: boolean;
  isLoadingAllData: boolean;

  shouldBlockSave: boolean;
  shouldShowAlert: boolean;
  alertMessage?: string;

  shouldShowModal: boolean;
  modalMessage?: string;

  showRemoveGameModal: boolean;

  isStatusConnected: boolean;
}

interface BaseComponentProps {
  theGame?: WhiteElephantGameData;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps & RouteComponentProps;

export default class PlayWhiteElephant<
  State extends ComponentState = ComponentState
> extends React.Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      ...this.state,
      firebaseUser: null,
      redirectHome: false,
      currentGame: {},
      playerIdToNames: {},
      currentGameGifts: [],
      lockedGiftIds: [],
      hasNewEventOccured: false,

      isSavingGift: false,
      shouldShowAlertGift: false,

      isChatBoxToggled: false,
      shouldShowChatBox: true,
      shouldToggleMessageReceived: false,

      shouldShowSelectModal: false,
      isSendingAction: false,
      
      showInfoModal: false,
      modalTitle: '',
      modalBody: '',

      isSending: false,
      isLoading: true,

      isLoadingNameData: true,
      isLoadingGiftData: true,
      isLoadingGameData: false,
      isLoadingAllData: true,

      shouldBlockSave: false,
      shouldShowAlert: false,
      shouldShowModal: false,

      showRemoveGameModal: false,

      isStatusConnected: false,
    };
    this.handleBack = this.handleBack.bind(this);
  }

  private unsubscribeFromAuth: any = null;


  handleBack() {
    this.props.history.push('/whiteelephantgame');
  }



  async componentDidMount () {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        let myUserId = localStorage.getItem(WhiteElephantLocalStorageKey.myUserId) ?? undefined;
        if (myUserId === undefined) {
          const myUserData = await fetchPlayerByAuthId(user);
          myUserId = myUserData?.id;
        }
        this.setState({ 
          firebaseUser: user, 
          myUserId, 
        }, async () => {
          // User loaded
          const currentGameLocalStorageData = localStorage.getItem(WhiteElephantLocalStorageKey.currentGame)
          if (currentGameLocalStorageData && myUserId) {
            // console.log(`currentGameLocalStorageData = ${currentGameLocalStorageData}`);
            const currentGameFromLocalStorage = convertDatastringToWhiteElephantGameData(currentGameLocalStorageData);
            this.setState({ currentGame: currentGameFromLocalStorage }, async () => {
              await this.fetchAllData();

              const element = document.getElementById("TopOfScreen");
              element?.scrollIntoView({behavior: 'auto'});
            });
          } else {
            this.setState({ redirectHome: true });
          }
          this.setState({ isLoading: false });
        });
      } else {
        await auth.signOut().then(() => {
          this.setState({ firebaseUser: null, redirectHome: true });
        });
      }
    });
  }

  private async fetchAllData() {
    await this.fetchPlayerNames();
    await this.fetchGifts();
    await downloadWhiteElephantGameEvents(this.state.currentGame, this.downloadEventsCallback, this.state.lastGameEvent?.createdAtUnix);
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    const { redirectHome } = this.state;
    if (redirectHome) {
      return <Redirect push to={WHITE_ELEPHANT_GAME} />;
    }

    return (
      <div className="Window-Size-Base">
        {/* {this.renderGameStarted()} */}

        {this.state.currentGame?.status === WhiteElephantGameStatus.not_started && this.renderGameNotStarted()}
        {this.state.currentGame?.status === WhiteElephantGameStatus.game_room_open && this.renderGameRoom()}
        {this.state.currentGame?.status === WhiteElephantGameStatus.started && this.renderGameStarted()}
        {this.state.currentGame?.status === WhiteElephantGameStatus.finished && this.renderGameFinished()}
      </div>
    );
  }

  // === Render primary views for Game Not Started, Game Room, Game Started, and Game Finished ===
  private renderGameNotStarted() {
    return (
      <div className="Window-Size-Base">
        <div className="PlayWhiteElephant-MainBase">
          <div className="Top-Row">
            <div className="TopLeftColumn">
              <img src={leftArrow} alt='Back Arrow' className="Top-Left-Button" 
                onClick={this.handleBack}/> 
            </div>
            <div className="TopMiddleColumn" />
            <div className="TopRightColumn">
              <img src={logoRed} alt='Elephant Gift Exchange'   className='PlayWhiteElephant-GameStarted-Logo-Top' 
                onClick={() => {
                  this.setState({ redirectHome: true });
                }}/> 
            </div>
          </div>
          <div className={"Row PlayWhiteElephant-Base"}>
            {this.renderEditGiftForm()}
            {this.state.myUserId && this.state.myUserId === this.state.currentGame.hostId &&
            this.renderHostEditGameRulesForm()
            }
          </div>
        </div>
      </div>
    );
  }
  private renderGameRoom() {
    if (this.state.isLoadingAllData || this.state.isLoadingNameData || this.state.isLoadingGiftData) {
      return (
        <div className={"Window-Size-Base Full-100vh-100vw Center-Middle"}>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
          {' Loading Game Data...'}
        </div>
      );
    } else {
      return (
        <div className={"Window-Size-Base"}>
          <div className={"PlayWhiteElephant-MainBase NoOverflowX"}>
            <div className="Top-Row" id="Top-Section">
              <div className="TopLeftColumn">
                {this.renderMenuButton()}
              </div>
              <div className="TopMiddleColumn">
                <div className="PlayWhiteElephant-GameStarted-Cards-Header" id="GameNameHeader">
                  {this.state.currentGame.gameName}
                </div>
              </div>
              <div className="TopRightColumn">
                <img src={logoRed} alt='Elephant Gift Exchange'   className='PlayWhiteElephant-GameStarted-Logo-Top' 
                  onClick={() => {
                    this.setState({ redirectHome: true });
                  }}/> 
              </div>
            </div>
            <div className={"MainBody-Not-Top-Row"}>
              {this.state.firebaseUser && this.state.myUserId && 
              <GameRoomView firebaseUser={this.state.firebaseUser} myUserId={this.state.myUserId!} currentGame={this.state.currentGame} playerIdToPlayer={this.state.playerIdToPlayer} myGiftSelection={this.state.myGiftSelection} onGameStartCallback={this.onGameStartCallback} onGiftSaveCallback={this.onGiftPickSaveCallback} onGameStatusChangeCallback={this.onGameStatusChangeCallback} onTabChangeCallback={this.handleGameRoomViewTabChange} />}
              {this.state.shouldShowChatBox && 
              <div>
                <Animated animationIn="slideInRight" animationOut="slideOutRight" animationInDuration={1000} animationOutDuration={1000} isVisible={this.state.isChatBoxToggled} className="AnimationOnTop">
                  {this.renderEventsBox()}
                </Animated>
                {this.renderChatButton(false)}
              </div>}
            </div>
          </div>
        </div>
      );
    }
  }
  private renderGameStarted() {
    // console.log('renderGameStarted');
    if (this.state.isLoadingAllData || this.state.isLoadingNameData || this.state.isLoadingGiftData) {
      return (
        <div className={"Window-Size-Base Full-100vh-100vw Center-Middle"}>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
          {' Loading Game Data...'}
        </div>
      );
    } else {
      let theGifType = gifType.unwrap;
      if (this.state.hasNewEventOccured && this.state.lastGameEvent) {
        if (this.state.lastGameEvent.action === WhiteElephantGameAction.open) {
          theGifType = gifType.unwrap;
        } else if (this.state.lastGameEvent.action === WhiteElephantGameAction.take) {
          theGifType = gifType.takeGift;
        } else if (this.state.lastGameEvent.action === WhiteElephantGameAction.keep) {
          theGifType = gifType.keep;
        }
      }
      return (
        <div className="Window-Size-Base">
          {this.state.shouldShowSelectModal && this.renderGiftSelectionModal()}
          {this.state.showInfoModal && this.renderInfoModal()}
          {this.state.hasNewEventOccured && this.state.lastGameEvent && this.state.lastGameEvent.playerId && this.state.giftIdToGift && this.state.lastGameEvent.giftIdTaken && this.state.lastGameEvent.action && 
          <ActionGifphyModal shouldShow={this.state.hasNewEventOccured} 
            actionNum={this.state.lastGameEvent.actionNum} 
            action={this.state.lastGameEvent.action} 
            gifType={theGifType} 
            playerName={this.state.playerIdToPlayer[this.state.lastGameEvent.playerId]?.name ?? ''}
            giftName={this.state.giftIdToGift[this.state.lastGameEvent.giftIdTaken]?.productName ?? ''}
            takenFromPlayerName={this.state.lastGameEvent.takenFromPlayerId ? this.state.playerIdToPlayer[this.state.lastGameEvent.takenFromPlayerId].name : undefined}
            handleCloseCallback={this.handleGiphyModalClose} />}
          <div className="Top-Row" id="Top-Section">
            <div className="TopLeftColumn">
              {this.renderMenuButton()}
            </div>
            <div className="TopMiddleColumn">
              <div className="PlayWhiteElephant-GameStarted-Cards-Header" id="GameNameHeader">
                {this.state.currentGame.gameName}
              </div>
            </div>
            <div className="TopRightColumn">
              <img src={logoRed} alt='Elephant Gift Exchange'   className='PlayWhiteElephant-GameStarted-Logo-Top' 
                onClick={() => {
                  this.setState({ redirectHome: true });
                }}/> 
            </div>
          </div>
          {this.renderTopOfGameStarted()}
        </div>
      );
    }
  }
  private renderGameFinished() {
    const myUserId = this.state.myUserId;
    const giftIdToPlayerId: {[key: string]: string} | undefined = this.state.lastGameEvent?.giftIdToPlayerId;
    const giftIdToGift: {[key: string]: GiftSelectionData} | undefined = this.state.giftIdToGift;
    let idOfGiftToRecieve: string | undefined;
    if (giftIdToPlayerId) {
      for(let giftId in giftIdToPlayerId) {
        if(giftIdToPlayerId[giftId] === myUserId) {
          idOfGiftToRecieve = giftId;
        }
      }
    }
    if (giftIdToGift && giftIdToPlayerId && myUserId && idOfGiftToRecieve) {
      // console.log(`giftIdToGift: ${JSON.stringify(giftIdToGift)}`);
      // console.log(`idOfGiftToRecieve: ${JSON.stringify(idOfGiftToRecieve)}`);
      const giftToReceive: GiftSelectionData = giftIdToGift[idOfGiftToRecieve];
      // console.log(`giftToReceive: ${JSON.stringify(idOfGiftToRecieve)}`);
      const giftToRecieveFrom = this.state.playerIdToPlayer[giftToReceive.userId].name ? this.state.playerIdToPlayer['' + giftToReceive.userId].name : this.state.playerIdToPlayer['' + giftToReceive.userId].email;
      let giftToSend: GiftSelectionData[] = this.state.currentGameGifts.filter(theGift => theGift.userId === myUserId);
      const playerIdToSendTo = giftIdToPlayerId[giftToSend[0].id];
      const playerToSendTo = this.state.playerIdToPlayer[playerIdToSendTo];
      return (
        <div className={"PlayWhiteElephant-MainBase PlayWhiteElephant-Base-Semi-Transparent"}>
          {this.state.showRemoveGameModal && 
          <RemoveGameModal firebaseUser={this.state.firebaseUser} shouldShow={this.state.showRemoveGameModal} gameId={this.state.currentGame.id} onCloseCallback={() => { this.setState({ showRemoveGameModal: false}) }} onGameRemovedCallback={() => {
            this.setState({ redirectHome: true });
          }} />}
          <div className="Top-Row" id="Top-Section">
            <div className="TopLeftColumn">
              {this.renderMenuButton()}
            </div>
            <div className="TopMiddleColumn">
            </div>
            <div className="TopRightColumn">
              <img src={logoRed} alt='Elephant Gift Exchange'   className='PlayWhiteElephant-GameStarted-Logo-Top' 
                onClick={() => {
                  this.setState({ redirectHome: true });
                }}/> 
            </div>
          </div>
          <div className={"MainBody-Not-Top-Row Center-Middle"}>
            {/* <div className="Center-Screen"> */}
              <div className={"PlayWhiteElephant-GameFinished-Base"}>
                <h3 className="FullWidth"> Game Finished! </h3>
                <h5 className="FullWidth"> You will be receiving {giftToReceive.productName} from {giftToRecieveFrom} </h5>

                <div className="FullWidth"> Please send your gift {giftToSend[0].productName} to: </div>
                <h6>
                  <div className="FullWidth"> {playerToSendTo.name} </div>
                  <div className="FullWidth"> {playerToSendTo.address1} </div>
                  {playerToSendTo.address2 && <div> {playerToSendTo.address2} </div>}
                  {playerToSendTo.city && 
                  <div className="FullWidth"> {playerToSendTo.city}, {playerToSendTo.state} {playerToSendTo.zipcode} </div>}
                  <div className="FullWidth">
                    {giftToSend[0].url && <a href={generateMyAmazonUrl(giftToSend[0].url)}>Link to gift</a>}
                  </div>
                </h6>
              </div>
            {/* </div> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className={"PlayWhiteElephant-MainBase PlayWhiteElephant-Base-Semi-Transparent"}>
        {this.state.showRemoveGameModal && 
        <RemoveGameModal firebaseUser={this.state.firebaseUser} shouldShow={this.state.showRemoveGameModal} gameId={this.state.currentGame.id} onCloseCallback={() => { this.setState({ showRemoveGameModal: false}) }} />}
          <div className="Top-Row" id="Top-Section">
            <div className="TopLeftColumn">
              {this.renderMenuButton()}
            </div>
            <div className="TopMiddleColumn">
            </div>
            <div className="TopRightColumn">
              <img src={logoRed} alt='Elephant Gift Exchange'   className='PlayWhiteElephant-GameStarted-Logo-Top' 
                onClick={() => {
                  this.setState({ redirectHome: true });
                }}/> 
            </div>
          </div>
          <div className={"MainBody-Not-Top-Row Center-Middle"}>
            <div className="Center-Screen">
              {(this.state.isLoadingAllData || this.state.isLoadingNameData || this.state.isLoadingGiftData) && 
              <div className={"Center-Middle"}>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              {' Determining Final Game Results...'}
            </div>}
              <div> Game Finished! </div>
            </div>
          </div>
        </div>
      );
    }

  }

 // ----- Render Menu Bar Components -----
 private renderMenuButton() {
   return (
    <DropdownButton id="Menu" variant="transparent" title={
      <img src={menuBlack} alt='menu' className="Menu-Button" />
      } className="Dropdown-Menu">
      <Dropdown.Item onClick={() => {
        // console.log(`Clicked  |  ${this.state.isUserDataLoaded}`);
        this.setState({ redirectHome: true });
      }}>Back to My Games</Dropdown.Item>
      {this.state.currentGame.status === WhiteElephantGameStatus.finished &&
        <Dropdown.Item onClick={async () => {
          this.setState({ showRemoveGameModal: true });
        }}>Remove Game</Dropdown.Item>
      }
      {this.state.currentGame.hostId === this.state.myUserId &&
        <Dropdown.Item onClick={async () => {
          // console.log(`Clicked  |  ${this.state.isUserDataLoaded}`);
          const newGameState: WhiteElephantGameData = this.state.currentGame;
          newGameState.status = WhiteElephantGameStatus.not_started;
          newGameState.gifts = [];
          newGameState.playerOrder = [];
          await this.updateGame(newGameState)
          .then(() => {
            resetWhiteElephantGameEvents(newGameState.id);
            updateGameStatus(newGameState.id, newGameState.status, () => {});
            this.setState({ currentGame: newGameState });
          });
        }}>Reset Game Status</Dropdown.Item>
      }
      {this.state.currentGame.hostId === this.state.myUserId &&
        <Dropdown.Item onClick={async () => {
          // console.log(`Clicked  |  ${this.state.isUserDataLoaded}`);
          const newGameState = this.state.currentGame;
          newGameState.status = WhiteElephantGameStatus.finished;
          await this.updateGame(newGameState)
          .then(() => {
            updateGameStatus(newGameState.id, newGameState.status, () => {});
            this.setState({ currentGame: newGameState });
          });
        }}>Finish Game</Dropdown.Item>
      }
    </DropdownButton>
   );
 }

 // ------ Render Chat Components --------
 private renderChatButton(isGameStarted: boolean) {
   const chatButtonClass = isGameStarted ? 'GameStartedChatButtonBottomRight' : 'ChatButtonBottomRight';
   return (
     <div className={`${chatButtonClass} Center-Middle`}>
      <ReactCardFlip
        isFlipped={this.state.isChatBoxToggled}
        flipSpeedBackToFront={1}
        flipSpeedFrontToBack={1}
      >
        <button className={"ChatButtonStyles"} onClick={()=>{
          this.setState({ isChatBoxToggled: !this.state.isChatBoxToggled});
        }}>
          {this.state.shouldToggleMessageReceived ? 
            <img src={whiteChatRedCircleIcon} alt="chat-icon" className="ChatButtonImage" /> :
            <img src={whiteChatIcon} alt="chat-icon" className="ChatButtonImage" />}
        </button>

        <button className={"CancelButtonStyles"} onClick={()=>{
          this.setState({ isChatBoxToggled: !this.state.isChatBoxToggled, shouldToggleMessageReceived: false });
        }}>
          <img src={whiteCancelIcon} alt="chat-icon" className="ChatButtonImage" />
        </button>
      </ReactCardFlip>
    </div>
   );
 }

 // ----- Render components for Game Not Started -----
  private renderEditGiftForm() {
    if (this.state.isLoadingAllData || this.state.isLoadingNameData || this.state.isLoadingGiftData) {
      return (
        <div className={"Window-Size-Base Full-100vh-100vw Center-Middle"}>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
          {' Loading Game Data...'}
        </div>
      );
    } else {
      if(this.state.firebaseUser && this.state.myUserId ) {
        return (
          <div className="PlayWhiteElephant-GiftForm">
            <GiftPickerView firebaseUser={this.state.firebaseUser} myUserId={this.state.myUserId!} currentGame={this.state.currentGame} myGiftSelection={this.state.myGiftSelection} onSaveCallback={this.onGiftPickSaveCallback} />
          </div>
        );
      } else {
        return (
          <div></div>
        );
      }
    }
  }
  private renderHostEditGameRulesForm() {
    if (this.state.isLoadingAllData || this.state.isLoadingNameData || this.state.isLoadingGiftData) {
      return <div />;
    } else {
      if (this.state.firebaseUser && !!this.state.myUserId) {
        return (
          <div className="PlayWhiteElephant-Form">
            <HostEditGameRulesView firebaseUser={this.state.firebaseUser} myUserId={this.state.myUserId!} currentGame={this.state.currentGame} onStartGameRoomCallback={this.onStartGameRoomCallback} />
            {this.state.shouldShowAlert && <Alert variant="danger" onClose={() => this.setState({ shouldShowAlert: false })} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>
                {this.state.alertMessage}
              </p>
              </Alert>}
          </div>
        );
      } else {
        return <div />;
      }
    }
  }

  private onStartGameRoomCallback = async (savedWhiteElephantGameData: WhiteElephantGameData) => {
    const newCurrentGame = savedWhiteElephantGameData ;
    newCurrentGame.status = WhiteElephantGameStatus.game_room_open;
    await this.updateGame(newCurrentGame)
    .catch(() => {
      this.setState({ shouldShowAlert: true, alertMessage: `Unexpected error. Please try again.` });
    });
  }

  // ----- Components for Gifts Deck -----
  private renderGiftsInCardDeck() {
    // console.log(`this.state.lastGameEvent: ${JSON.stringify(this.state.lastGameEvent)}`);
    // console.log(`this.state.currentGameGifts: ${JSON.stringify(this.state.currentGameGifts)}`);
    let currentGameGifts: GiftSelectionData[] = this.state.currentGameGifts;
    if (this.state.currentGame.gifts && this.state.currentGame.gifts.length > 0 && this.state.giftIdToGift) {
      currentGameGifts = [];
      for(const giftId of this.state.currentGame.gifts) {
        currentGameGifts.push( this.state.giftIdToGift[giftId] );
      }
    }
    // console.log(` ==== currentGameGifts: ${JSON.stringify(currentGameGifts)}`);
    return (
      <div className="PlayWhiteElephant-ActiveGame-CardDeck">
        {currentGameGifts?.map((giftSelection: GiftSelectionData, index: number) => {
          // console.log(` ==== giftSelection: ${JSON.stringify(giftSelection)}`);
          return (
            <div className={"PlayWhiteElephant-ActiveGame-Card PlayWhiteElephant-ActiveGame-Card-Size"}  key={`CarouselCard-${giftSelection.id}`} onClick={() => { 
              // console.log(`clicked Card`); 
              this.onGiftCarouselItemClick(giftSelection);
              }}>
              <Carousel className="PlayWhiteElephant-ActiveGame-CardCarousel">
                {this.state.lastGameEvent?.giftIdToPlayerId?.[giftSelection.id] ? 
                ( // Gift is unwrapped
                  giftSelection.giftImageIds ? 
                  (
                    giftSelection.giftImageIds.length > 0 ? (
                      giftSelection.giftImageIds.map(giftImageId => {
                      return (
                        <Carousel.Item key={`CarouselItem-UnwrappedGift-${giftImageId}`} className="Gift-Carousel-Item" >
                          <ImageDownloadComponent imageIdPath={giftImageId} className="PlayWhiteElephant-ActiveGame-Card-Size" />
                        </Carousel.Item>);
                    })
                    ) : (
                      <Carousel.Item key={`CarouselItem-UnwrappedGift-${giftSelection.id}`} className="Gift-Carousel-Item" >
                        <ImageDownloadComponent imageIdPath={'giftBox1'} />
                        <Carousel.Caption className={"Gift-Carousel-Item-Caption"}>
                          {`${giftSelection.productName} (No image provided)`}
                        </Carousel.Caption>
                      </Carousel.Item>
                    )
                  ) : (
                <Carousel.Item key={`CarouselItem-WrappedGift`}>
                  <img id={`${giftSelection.id}-unwrappedGift`} alt={`${giftSelection.id}-unwrappedGift`} src={giftBox2} className="Full-Size" />
                </Carousel.Item>
                  )
                ) :  
                ( // Gift is wrapped
                  giftSelection.wrappedGiftId ? 
                  (giftSelection.wrappedGiftId.map(giftImageId => {
                    return (
                      <Carousel.Item key={`CarouselItem-WrappedGift-${giftImageId}`} className="Gift-Carousel-Item" >
                        <ImageDownloadComponent imageIdPath={giftImageId} className="PlayWhiteElephant-ActiveGame-Card-Size" />
                      </Carousel.Item>);
                  })) : 
                <Carousel.Item key={`CarouselItem-WrappedGift`}>
                  <img id={`${giftSelection.id}-wrappedGift`} alt={`${giftSelection.id}-wrappedGift`} src={giftBox1} className="Full-Size" />
                </Carousel.Item>)
                }
              </Carousel>
            </div>
          );
        })}
      </div>
    );
  }
  private onGiftCarouselItemClick = (giftSelection: GiftSelectionData) => {
    if(
      !!this.state.lastGameEvent && 
      isNotFinalAction(this.state.lastGameEvent!)
      ) {
        // console.log(`Selecting your own gift`);
        // this.setState({ showInfoModal: true, modalTitle: 'Oops', modalBody: 'Looks like you picked your own gift. Try selecting another gift.' });
        let modalBody = '';
        if (this.state.myUserId && this.state.lastGameEvent?.nextActionPlayerId && 
          this.state.lastGameEvent.nextActionPlayerId === this.state.myUserId) {
          modalBody = 'Do you want this item?';
          if (giftSelection.userId === this.state.myUserId) {
            modalBody += ' Looks like you picked your own gift.';
          }
        }
        this.setState({ shouldShowSelectModal: true, myTurnGiftSelection: giftSelection, modalBody });
      } else {
        // Do nothing / or show user that it's not their turn
      }
  }
  private executeMyAction = async (lastGameEvent: WhiteElephantGameEventData, myUserId: string, myAction: string, currentGameGifts: GiftSelectionData[], giftIdTaken: string, takenFromPlayerId?: string) => {
    const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/WhiteElephantGameEvent/Action/`;
    const myActionSelection: MyActionSelection = {
      lastGameEvent, myUserId, myAction, currentGameGifts, giftIdTaken, takenFromPlayerId,
    };

    // console.log(`myActionSelection: ${JSON.stringify(myActionSelection)}`);
    const bearerToken = await this.state.firebaseUser?.getIdToken();
    await fetch(targetUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
      body: JSON.stringify(myActionSelection),
    })
    .then(async response => {
      const responseBody = await response.text();
      // console.log(`executeMyAction responseBody: ${responseBody}`);

      const lastGameEvent = JSON.parse(responseBody) as WhiteElephantGameEventData;
      // console.log(`lastGameEvent: ${JSON.stringify(lastGameEvent)}`);

      const collectionPathName = `${WhiteElephantGameEventCollection}/${this.state.currentGame.id}`;
      await realtimeDb.ref(collectionPathName).child(lastGameEvent.eventId).set(lastGameEvent)
      .then(() => {
        const giftIdToPlayerId: {[key: string]: string} = this.state.giftIdToPlayerId ?? {};
        giftIdToPlayerId[myUserId] = myUserId;
        this.setState({ 
          isSendingAction: false,
          lastGameEvent,
          shouldShowSelectModal: false,
          giftIdToPlayerId,
        });
      })
      .catch((error) => {
        console.log(`Error: ${JSON.stringify(error)}`);
        this.setState({ 
          isSendingAction: false,
          shouldShowSelectModal: false,
        });
      });;
    })
    .catch((error) => {
      console.log(`Error: ${JSON.stringify(error)}`);
      this.setState({ 
        isSendingAction: false,
        shouldShowSelectModal: false,
      });
    });
  }
  private handleGiftCarouselClose = () => { this.setState({ shouldShowSelectModal: false }) }
  private renderGiftSelectionModal() {
    const giftSelection = this.state.myTurnGiftSelection;
    const isThisGiftAlreadyOpen: boolean = (!!giftSelection && !!this.state.lastGameEvent?.giftIdToPlayerId?.[giftSelection.id]);
    const isThisTakingFromMyself: boolean = (!!giftSelection && this.state.lastGameEvent?.giftIdToPlayerId?.[giftSelection.id] === this.state.myUserId);
    let modalBody: string = this.state.modalBody;

    if(giftSelection && this.state.lastGameEvent && this.state.myUserId) {
      
      const isTakeAllowedHere: boolean = (this.state.lastGameEvent.canTakeBeNextAction && !!this.state.lastGameEvent?.nextTakeableGiftIds?.[giftSelection.id] && !isThisTakingFromMyself);
      const isOpenAllowedHere: boolean = (this.state.lastGameEvent.canOpenBeNextAction && !this.state.lastGameEvent.giftIdToPlayerId[giftSelection.id]);
      const isKeepAllowedHere: boolean = (this.state.lastGameEvent.canKeepBeNextAction && this.state.lastGameEvent.giftIdToPlayerId[giftSelection.id] === this.state.myUserId);
      const isActionAllowed: boolean = isTakeAllowedHere || isOpenAllowedHere || isKeepAllowedHere;
      if (!isActionAllowed) {
        modalBody = '';
      }
    
      return (
        <Modal show={this.state.shouldShowSelectModal} onHide={this.handleGiftCarouselClose}>
          <Modal.Header closeButton={true}>
          <Modal.Title>{isThisGiftAlreadyOpen ? `${giftSelection.productName} (${this.state.playerIdToPlayer[this.state.lastGameEvent?.giftIdToPlayerId[giftSelection?.id]]?.name})` : `Unopened gift`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>    
            {(isThisGiftAlreadyOpen) ? 
            (giftSelection.giftImageIds && 
            <div>
              <Carousel className="PlayWhiteElephant-ActiveGame-CardCarousel">
                {giftSelection.giftImageIds.map((imageId) => {
                  return (
                    <CarouselItem key={'carousel-'+imageId}>
                      <ImageDownloadComponent imageIdPath={imageId}/>
                    </CarouselItem>
                  );
                })}
              </Carousel>
              <h5>
                {giftSelection.description && `Gift Description: ${giftSelection.description}`}
              </h5>
            </div>
            ) : 
            (giftSelection.wrappedGiftId && 
              <Carousel className="PlayWhiteElephant-ActiveGame-CardCarousel">
                {giftSelection.wrappedGiftId.map((imageId) => {
                  return (
                    <CarouselItem key={'carousel-'+imageId}>
                      <ImageDownloadComponent imageIdPath={imageId}/>
                    </CarouselItem>
                  );
                })}
              </Carousel>)}
              
            {modalBody}
          </Modal.Body>
          {this.state.lastGameEvent?.nextActionPlayerId === this.state.myUserId && 
            <Modal.Footer>
            {isTakeAllowedHere &&
            <Button variant="secondary" onClick={() => this.executeMyAction(this.state.lastGameEvent!, this.state.myUserId!, WhiteElephantGameAction.take, this.state.currentGameGifts, giftSelection.id, this.state.lastGameEvent!.giftIdToPlayerId[giftSelection.id])} disabled={this.state.isSendingAction}>
            {this.state.isSendingAction && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
              Take
            </Button>}
            {isOpenAllowedHere && 
            <Button variant="secondary" onClick={() => this.executeMyAction(this.state.lastGameEvent!, this.state.myUserId!, WhiteElephantGameAction.open, this.state.currentGameGifts, giftSelection.id)} disabled={this.state.isSendingAction}>
            {this.state.isSendingAction && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
              Open
            </Button>}
            {isKeepAllowedHere && 
            <Button variant="secondary" onClick={() => this.executeMyAction(this.state.lastGameEvent!, this.state.myUserId!, WhiteElephantGameAction.keep, this.state.currentGameGifts, giftSelection.id)} disabled={this.state.isSendingAction}>
            {this.state.isSendingAction && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
              Keep
            </Button>}
          </Modal.Footer>}
        </Modal>
      );
    } else {
      return (<div></div>);
    }
  }
  
  private renderInfoModal() {
    return (
      <InfoModal shouldShow={this.state.showInfoModal} title={this.state.modalTitle} bodyMessage={this.state.modalBody} onClose={() => this.setState({ showInfoModal: false })} />
    );
  }
  private handleGiphyModalClose = () => {
    this.setState({ hasNewEventOccured: false });
  }

  // ----- Components for Top of Game Started -----
  private renderTopOfGameStarted() {
    return (
      <div className="PlayWhiteElephant-GameStarted-Cards-Section">
        <div className={"Full-Size PlayWhiteElephant-MainBase"}>
          {this.state.lastGameEvent?.nextActionPlayerId === this.state.myUserId && 
          <YourTurnModal shouldShow={true}/>}
          {(this.state.lastGameEvent && !isNotFinalAction(this.state.lastGameEvent!)) && <InfoModal shouldShow={true} title={`End of Game`} bodyMessage={`Thanks for playing!`} onClose={() => {}} />}
          <div className="PlayWhiteElephant-GameStarted-Cards-Row">
            {(!this.state.currentGameGifts || this.state.currentGameGifts?.length === 0) && (
              <div className="Full-Size">
                <div className="PlayWhiteElephant-GameStarted-Cards-Body">No Gifts Yet</div>
                {this.state.isLoading && <Spinner animation="border" />}
              </div>
            )}
            {(this.state.currentGameGifts && this.state.currentGameGifts.length > 0) && this.renderGiftsInCardDeck()}
          </div>
          <div className={"No-Overflow"}>
            <Animated animationIn="slideInRight" animationOut="slideOutRight" animationInDuration={1000} animationOutDuration={1000} isVisible={this.state.isChatBoxToggled} className="GameStartedAnimationOnTop">
              {this.renderEventsBox()}
            </Animated>
            { // TODO: Should indicate that a new message received. Bounce animation ideal.
            }
            {this.renderChatButton(true)}
          </div>
          <div className="PlayWhiteElephant-GameStarted-LastEvent-Container">
            {this.renderLastGameActionSection()}
          </div>
        </div>
      </div>
    );
  }

  // ----- Components for Rendering the Events Box ----
  private renderEventsBox() {
    document.getElementById("Top-Section")?.scrollIntoView({behavior: 'auto'});
    return (
      <div className="PlayWhiteElephant-GameStarted-Events-Container">
        <ChatComponent user={this.state.firebaseUser!} gameId={this.state.currentGame.id} gameName={this.state.currentGame.gameName} myUserId={this.state.myUserId!} playerIdToPlayer={this.state.playerIdToPlayer} onNewMessageCallback={() => {
          if (this.state.isChatBoxToggled === false) {
            this.setState({ shouldToggleMessageReceived: true  });
          }
        }} />
      </div>
    );
  }
  private renderLastGameActionSection() {
    // console.log(`this.state.lastGameEvent: ${JSON.stringify(this.state.lastGameEvent)}`);
    let doIHaveAGiftNow = false;
    let myGiftText = '';
    let myCurrentGift: GiftSelectionData | undefined;
    if( !!this.state.lastGameEvent ) {
      const lastGameEvent: WhiteElephantGameEventData = this.state.lastGameEvent!;
      const giftIdToPlayerId = lastGameEvent.giftIdToPlayerId;
      Object.keys(giftIdToPlayerId).forEach((giftId: string) => {
        if (giftIdToPlayerId[giftId] === this.state.myUserId && this.state.currentGameGifts) {
          myCurrentGift = this.state.currentGameGifts.find((gift) => gift.id === giftId);
          if (myCurrentGift) {
            myGiftText = `You have: ${myCurrentGift.productName.substring(0,50)}`;
            if (myCurrentGift.productName.length > 50) {
              myGiftText += '...';
            }
            doIHaveAGiftNow = true;
          }
        }
      });
    }

    return (
      <div className="Full-Size">
        {this.state.lastGameEvent &&
          <MultipleColumnView renderColumns={[this.renderLastEventMessage(), this.renderNextTurnMessage()]}/>
        }
        <div className={"Column-Container-Flex MarginTop-5px"}>
          <div className={""}>
            {doIHaveAGiftNow && 
            <Button variant='light' onClick={() => {
              this.setState({ shouldShowSelectModal: true, myTurnGiftSelection: myCurrentGift, modalBody: '' });
            }}>
              {myGiftText}
            </Button>}
          </div>
          <div className={"RightSide"}>
            <GamePlayerStatusView firebaseUser={this.state.firebaseUser} myUserId={this.state.myUserId!} playerIdToPlayer={this.state.playerIdToPlayer} />
          </div>
        </div>
      </div>
    );
  }
  private renderLastEventMessage() {
    let stringToDisplay = '';
    if (this.state.lastGameEvent?.actionNum === 1) {
      return (
        <div className="Full-Size">
          {`Round 1! Let's Play.`}
        </div>
      );
    }
    else if(this.state.lastGameEvent?.playerId && this.state.lastGameEvent?.action) {
      const playerId = this.state.lastGameEvent.playerId;
      if (this.state.myUserId && this.state.myUserId === playerId) {
        stringToDisplay += 'You'
      } else {
        stringToDisplay += this.state.playerIdToPlayer[playerId].name ? this.state.playerIdToPlayer[playerId].name : this.state.playerIdToPlayer[playerId].email;
      }
      const action = this.state.lastGameEvent.action;
      if (action === WhiteElephantGameAction.take && this.state.lastGameEvent.giftIdTaken) {
        const giftIdTaken = this.state.lastGameEvent.giftIdTaken;
        stringToDisplay += ' took a gift'
        if (giftIdTaken && this.state.previousGameEvent) {
          if (this.state.myUserId && this.state.previousGameEvent?.giftIdToPlayerId && 
            this.state.previousGameEvent.giftIdToPlayerId[giftIdTaken] === this.state.myUserId) {
            stringToDisplay += ' from you.'
          } else if (this.state.previousGameEvent.giftIdToPlayerId) {
            stringToDisplay += ' from ' + this.state.playerIdToPlayer[this.state.previousGameEvent.giftIdToPlayerId[giftIdTaken]].name;
          }
        }
      } else if (action === WhiteElephantGameAction.open) {
        stringToDisplay += ' opened a new gift.'
      } else if (action === WhiteElephantGameAction.keep) {
        stringToDisplay += ' kept the gift.'
      }
      return (
        <div className="Full-Size">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 200 }}
            overlay={this.renderLastEventTooltip(`Last Action: ${stringToDisplay}`)}
          >
            <Button variant='transparent' className={"NextTurn-TooltipButton Left"}>
              {`Last Action: ${stringToDisplay}`}
            </Button>
          </OverlayTrigger>
        </div>
      );
    }
  }
  private renderNextTurnMessage() {
    // console.log(`nextActionPlayerId: ${this.state.lastGameEvent?.nextActionPlayerId}`);
    let textToDisplay: string = '';
    if (this.state.lastGameEvent && isNotFinalAction(this.state.lastGameEvent!)) {
      textToDisplay = `Now: ${this.getPlayerNameToDisplay(this.state.lastGameEvent.nextActionPlayerId, this.state.playerIdToPlayer)}'s turn`;
    }
    return (
      <div className="Full-Size">
        {( textToDisplay.length > 1 ) ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 200 }}
            overlay={this.renderLastEventTooltip(textToDisplay)}
          >
            <Button variant='transparent' className={"NextTurn-TooltipButton Right"}>
              {textToDisplay}
            </Button>
          </OverlayTrigger>
        ) : 
        `Game Finished`}
      </div>
    );
  }
  private getPlayerNameToDisplay(playerId: string, playerIdToPlayer: {[key: string]: UserData}) {
    // console.log(`- playerId: ${playerId}`);
    //   console.log(`- playerIdToPlayer: ${JSON.stringify(playerIdToPlayer)}`);
    //   console.log(`-- playerIdToPlayer[playerId]: ${JSON.stringify(playerIdToPlayer[playerId])}`);
    if (playerIdToPlayer[playerId]) {
      return playerIdToPlayer[playerId].name ? 
      playerIdToPlayer[playerId].name : 
      playerIdToPlayer[playerId].email;
    } else {
      return ''
    }
  }
  renderLastEventTooltip = (textToDisplay: string) => (
    <Popover id="button-tooltip" >
      <Popover.Content>
        {textToDisplay}
      </Popover.Content>
    </Popover>
  );

  // ~~~~~ Fetching Data ~~~~~
  private async fetchPlayerNames() {
    // console.log(`this.state.currentGame.players: ${JSON.stringify(this.state.currentGame.players)}`);
    const playerIdToPlayer: {[key: string]: UserData} = {};
    await Promise.all(
    this.state.currentGame.players.map(async playerId => {
      await fetchPlayerById(this.state.firebaseUser, playerId)
      .then((player) => {
        if(player) {
          playerIdToPlayer[playerId] = player;
        }
      });
    }));
    this.setState({ playerIdToPlayer, isLoadingNameData: false }, () => {
      // console.log(`this.state.playerIdToPlayer: ${JSON.stringify(this.state.playerIdToPlayer)}`);
    });
  }
  private async fetchGifts() {
    const gifts = await fetchGiftsByGameId(this.state.firebaseUser, this.state.currentGame.id);
    if (gifts) {
      // console.log(`gifts: ${JSON.stringify(gifts)}`);
      const giftIdToGift: {[key: string]: GiftSelectionData} = {};
      gifts.forEach(gift => {
        giftIdToGift[gift.id] = gift;
        if (gift.userId === this.state.myUserId!) {
          // This is my gift
          this.setState({ myGiftSelection: gift });
        }
      });
      // console.log(`giftIdToGift: ${JSON.stringify(giftIdToGift)}`);
      this.setState({ currentGameGifts: gifts, giftIdToGift, isLoadingGiftData: false });
    } else {
      // console.log(`else here`);
      this.setState({ isLoadingGiftData: false });
    }
  }

  private async fetchGameData() {
    this.setState({ isLoadingGameData: true });
    
    const currentGameLocalStorageData = localStorage.getItem(WhiteElephantLocalStorageKey.currentGame);
    if (currentGameLocalStorageData) {
      const currentGameFromLocalStorage: WhiteElephantGameData = convertDatastringToWhiteElephantGameData(currentGameLocalStorageData);

      // Get White Elephant Games
      const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/myGamesByIds/`;

      const bearerToken = await this.state.firebaseUser?.getIdToken();

      await fetch(targetUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${bearerToken}`,
        },
        body: `{ "myGameIds": ${JSON.stringify([currentGameFromLocalStorage.id])} }`,
      })
      .then(async response => {
        const responseBody = await response.text();
        const { data } = JSON.parse(responseBody);
        // console.log(`data = ${JSON.stringify(data)}`);
        // console.log(`data.length = ${JSON.stringify(data.length)}`);
        // console.log(`~~ data.gameTime = ${new Date(data[0].gameTime.toDate()).toLocalestring()}`);
        const gameData: WhiteElephantGameData[] = [];
        data.forEach((game: any) => {
          // console.log(' ****' + convertDatastringToWhiteElephantGameData(JSON.stringify(game)));
          gameData.push(convertDatastringToWhiteElephantGameData(JSON.stringify(game)));
        });
        console.log(`gameData = ${JSON.stringify(gameData)}`);
        if (gameData.length === 1) {
          const currentGame = gameData[0];
          this.setState({ 
            isLoadingGameData: false,
            currentGame,
          }, () => {
            localStorage.setItem(WhiteElephantLocalStorageKey.currentGame, JSON.stringify(currentGame));
          });
        } else {
          this.setState({ isLoadingGameData: false });
        }
      })
      .catch((error) => {
        // console.log(`fetch error = ${JSON.stringify(error)}`);
        this.setState({ isLoadingGameData: false });
      });
    } else {
      this.setState({ isLoadingGameData: false });
    }
  }

  private downloadEventsCallback = async (events: WhiteElephantGameEventData[]) => {
    const sortedEvents = events.sort((a,b) => a.createdAtUnix < b.createdAtUnix ? -1 : 1);
    // console.log(`sortedEvents: ${JSON.stringify(sortedEvents)}`);
    if(sortedEvents.length > 0) {
      const lastGameEvent = sortedEvents[sortedEvents.length-1];
      // console.log(`sortedEvents[sortedEvents.length-1]: ${JSON.stringify(sortedEvents[sortedEvents.length-1])}`);
      const giftIdToPlayerId: {[key: string]: string} = lastGameEvent.giftIdToPlayerId ? sortedEvents[sortedEvents.length-1].giftIdToPlayerId : {};
      const isNotSameEvent = lastGameEvent?.eventId !== this.state.lastGameEvent?.eventId;
      const previousGameEvent = this.state.lastGameEvent;
      const currentGame = this.state.currentGame;
      if ( !isNotFinalAction(lastGameEvent) ) {
        const currentGame = this.state.currentGame;
        currentGame.status = WhiteElephantGameStatus.finished;
        await this.fetchPlayerNames();
        await this.fetchGifts();
      }
      this.setState({ 
        lastGameEvent,
        previousGameEvent,
        isLoadingAllData: false,
        giftIdToPlayerId,
        currentGame,
      }, async () => {
        if(this.state.lastGameEvent && isNotSameEvent) {
          this.setState({hasNewEventOccured: true});
          if (this.state.currentGame.hostId === this.state.myUserId && !isNotFinalAction(lastGameEvent)) {
            const newGameState = this.state.currentGame;
            newGameState.status = WhiteElephantGameStatus.finished;
            await this.fetchPlayerNames();
            await this.fetchGifts();
            await this.updateGame(newGameState);
            updateGameStatus(newGameState.id, newGameState.status, () => {});
          }
        }
      });
    } else {
      this.setState({ isLoadingAllData: false });
    }
  }


  // Helper functions for Game Settings
  private async updateGame(newGameData: WhiteElephantGameData) {
    // console.log(`Update Game with : ${JSON.stringify(newGameData)}`);
    
    const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/?gameId=${newGameData.id}`;

    this.setState({ 
      isSending: true,
    })
    const bearerToken = await this.state.firebaseUser?.getIdToken();
    fetch(targetUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
      body: JSON.stringify(newGameData),
    })
    .then(async () => {
      await this.fetchPlayerNames();
      this.setState({ 
        isSending: false,
        currentGame: newGameData,
      });
      localStorage.setItem(WhiteElephantLocalStorageKey.currentGame, JSON.stringify(newGameData));
    })
    .catch((error) => {
      this.setState({ 
        isSending: false,
      });
    });
  }
  
  // Game Start Callback
  private onGameStartCallback = async (savedWhiteElephantGameData: WhiteElephantGameData, activePlayers: string[]) => {
    console.log(`activePlayers: ${activePlayers}`);
    const newCurrentGame = savedWhiteElephantGameData ;
    newCurrentGame.status = WhiteElephantGameStatus.started;
    await this.fetchGifts().then(async () => {
      const collectionPathName = `${WhiteElephantGameEventCollection}/${this.state.currentGame.id}`;
      
      // console.log(`#####  ${JSON.stringify(this.state.giftIdToPlayerId)}`);
      if (this.state.giftIdToGift && Object.keys(this.state.giftIdToGift!).length > 0) {
        const giftIdToGift: {[key: string]: GiftSelectionData} = this.state.giftIdToGift!;
        const activePlayersWithGifts: string[] = [];
        // Only play with gifts from active players
        const activeGiftIds: string[] = Object.keys(giftIdToGift).filter((giftId: string) => {
          return activePlayers.indexOf(giftIdToGift[giftId].userId) >= 0;
        });

        // console.log(`activeGiftIds: ${JSON.stringify(activeGiftIds)}`);

        activeGiftIds.forEach((giftId: string) => {
          activePlayersWithGifts.push(giftIdToGift[giftId]?.userId);
        });
        // Randomize Player Order
        const newOrder: string[] = this.shuffleArray(activePlayersWithGifts); 
        newCurrentGame.playerOrder = newOrder;

        
        // Randomize Gift Order
        const newGiftOrder: string[] = this.shuffleArray(activeGiftIds);
        newCurrentGame.gifts = newGiftOrder;

        console.log(` giftIdToGift ->  ${JSON.stringify(this.state.giftIdToGift)}`);
        const WhiteElephantGameEvent: WhiteElephantGameEventData = {
          eventId: `${uuid.v4()}`,
          actionNum: 1,
          gameId: this.state.currentGame.id,
          round: 1,
          nextActionPlayerId: '' + newOrder[0],
          canTakeBeNextAction: false,
          canOpenBeNextAction: true,
          canKeepBeNextAction: false,
          nextTakeableGiftIds: {},
          giftIdToPlayerId: {},
          createdAtUnix: parseInt((new Date().getTime() / 1000).toFixed(0)),
        }
        await realtimeDb.ref(`${collectionPathName}/${WhiteElephantGameEvent.eventId}`).set(WhiteElephantGameEvent).then(async () => {
          await this.updateGame(newCurrentGame).then(() => {
            updateGameStatus(newCurrentGame.id, newCurrentGame.status, () => {});
          });
        });
      } else {
        this.setState({ shouldShowAlert: true, alertMessage: `No gifts found for this game. Please add some gifts to your game.` });
      }
    });
  }

  private shuffleArray = (array: any[]) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }


  // Helper functions for Gift Selections
  private onGiftPickSaveCallback = (giftSelection: GiftSelectionData) => {
    this.setState({ 
      myGiftSelection: giftSelection,
    });
  }

  private handleGameRoomViewTabChange = (tabType: TabTypes) => {
    if (tabType === TabTypes.GameRoom) {
      this.setState({ shouldShowChatBox: true });
    } else {
      this.setState({ shouldShowChatBox: false });
    }
  }

  private onGameStatusChangeCallback = async (newGameStatus: string) => {
    if (newGameStatus !== this.state.currentGame.status) {
      await this.fetchPlayerNames();
      await this.fetchGifts();
      await this.fetchGameData();
      const currentGame: WhiteElephantGameData = this.state.currentGame;
      currentGame.status = newGameStatus;
      this.setState({ currentGame }, () => {
        localStorage.setItem(WhiteElephantLocalStorageKey.currentGame, JSON.stringify(currentGame));
      });
    }
  }
}