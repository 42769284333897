import { convertDataArraystringToGiftSelectionData, GiftSelectionData } from "../constants/dataConstants";


export async function fetchGiftById(firebaseUser: any, giftId: string) {
  // Get Player name from playerId
  const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/giftSelection/?giftSelectionId=${giftId}`;
  
  const bearerToken = await firebaseUser.getIdToken();
  const response = await fetch(targetUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization':`Bearer ${bearerToken}`,
    },
  })
  .catch((error) => {
    console.log(`error: ${error}`);
    return null;
  });

  if(response) {
    const responseBody = await response.text();
    // console.log(`responseBody = ${JSON.stringify(responseBody)}`);
    const data: GiftSelectionData = JSON.parse(responseBody);
    // console.log(`data = ${JSON.stringify(data)}`);
    return data;
  } else {
    return null;
  }
}

export async function fetchGiftsByGameId(firebaseUser: any, gameId: string) {
  // console.log('~~~~ Begin fetchGiftsByGameId');
  // Get Player name from playerId
  const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/giftSelection/?gameId=${gameId}`;
  
  const bearerToken = await firebaseUser.getIdToken();
  const response = await fetch(targetUrl, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
      'Authorization':`Bearer ${bearerToken}`,
    },
  })
  .catch((error) => {
    console.log(`error: ${error}`);
    return null;
  });

  if(response && response.ok) {
    const responseBody = await response.text();
    // console.log(`responseBody = ${JSON.stringify(responseBody)}`);
    const data: GiftSelectionData[] = convertDataArraystringToGiftSelectionData(responseBody);
    // console.log(`data = ${JSON.stringify(data)}`);
    return data;
  } else {
    return null;
  }
}

export function randomGiftBoxImageId() {
  const numberOfDefaultImages = 4;
  const randNumber = Math.floor(Math.random()*10) % numberOfDefaultImages;
  if (randNumber === 0)
    return "giftBox1";
  else if (randNumber === 1)
    return "giftBox2";
  else if (randNumber === 2)
    return "giftBox3";
  else
    return "giftBox4";
}

export function isOneOfDefaultGiftBoxes(imageId: string) {
  if(['giftBox1', 'giftBox2', 'giftBox3', 'giftBox4'].indexOf(imageId) > -1) 
    return true;
  else
    return false;
}
