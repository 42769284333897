import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';

import './App.css';
import { auth } from './firebase';
import HomePage from './home/HomePage';
import WhiteElephantGame from './whiteElephantGame/WhiteElephantGame';
import { HOME, WHITE_ELEPHANT_GAME, PLAY_WHITE_ELEPHANT } from './constants/routes';
import AuthProvider from './provider/AuthProvider';
import PlayWhiteElephant from './playWhiteElephant/PlayWhiteElephant';

const { Content } = Layout;

interface BaseComponentState {
  currentUser: any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = ChildProps;


export default class App<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState,
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      currentUser: null,
    };
  }

  private unsubscribeFromAuth: any = null;

  componentDidMount() {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(user => {
      this.setState({ currentUser: user });
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    return (
      <Layout className="App">
        <AuthProvider>
        {/* <Navbar/> */}
        <Content>
        <Switch>
          <Route path={PLAY_WHITE_ELEPHANT} component={PlayWhiteElephant} />
          <Route path={WHITE_ELEPHANT_GAME} component={WhiteElephantGame} /> 
          <Route path="/joinWithCode/:code" component={AppHomePage}/>
          <Route exact path={HOME} component={HomePage}/>
        </Switch>
        </Content>
        </AuthProvider>
      </Layout>
    );
  }

}


const AppHomePage = (matchedRoute: any) => {
  if ( matchedRoute?.match?.params?.code ) {
    return (
      <HomePage joinWithCode={matchedRoute?.match?.params?.code}/>
    );
  } else {
    return (
      <HomePage />
    );
  }
}
