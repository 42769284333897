import React from "react";
import { Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import './imageDownloadComponent.css';
import { storageRef } from "../firebase";
import giftBox1 from '../assets/default_box/gift-box-1.jpg';
import giftBox2 from '../assets/default_box/gift-box-2.jpg';
import giftBox3 from '../assets/default_box/gift-box-3.jpg';
import giftBox4 from '../assets/default_box/gift-box-4.jpg';

interface BaseComponentState {
  isDownloading: boolean;
  imageUrl: string;
}

interface BaseComponentProps {
  imageIdPath: string;
  className?: string;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;


export default class ImageDownloadComponent<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      isDownloading: true,
      imageUrl: '',
    };
  }

  async componentDidMount () {
    // console.log(`imageDownloadComponent: ${this.props.imageIdPath}`);
    if (this.props.imageIdPath === "giftBox1" 
    || this.props.imageIdPath === "giftBox2" 
    || this.props.imageIdPath === "giftBox3" 
    || this.props.imageIdPath === "giftBox3"
    || this.props.imageIdPath === "giftBox4") {
      this.setState({ isDownloading: false, imageUrl: this.getDefaultImageSrc() });
    } else {
      await this.fetchImage();
    }
  }

  render() {
    return (
      <div className={`Full-Size-Box ${this.props.className}`}>
        {this.state.isDownloading ? 
        <Spinner animation="border" /> :
        <img alt={`${this.props.imageIdPath}`} src={this.state.imageUrl} className={`Img-Size ${this.props.className}`} />}
      </div>
    );
  }

  private async fetchImage() {
    await storageRef.child(this.props.imageIdPath).getDownloadURL()
    .then((url: string) => {
      this.setState({ isDownloading: false, imageUrl: url });
    })
    .catch((error) => {
      this.setState({ isDownloading: false });
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          break;
    
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
    
        case 'storage/canceled':
          // User canceled the upload
          break;
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
  }

  private getDefaultImageSrc() {
    if (this.props.imageIdPath === "giftBox1")
      return giftBox1;
    else if (this.props.imageIdPath === "giftBox2")
      return giftBox2;
    else if (this.props.imageIdPath === "giftBox3")
      return giftBox3;
    else
      return giftBox4;
  }
}