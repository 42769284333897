import { WhiteElephantGameData, WhiteElephantGameEventCollection, WhiteElephantGameEventData } from "../constants/dataConstants";
import { realtimeDb } from '../firebase';

function getWhiteElephantGameEventsReference(gameId: string) {
  const collectionPathName = `${WhiteElephantGameEventCollection}/${gameId}`;

  return realtimeDb.ref(collectionPathName);
}

export async function downloadWhiteElephantGameEvents(thisGame: WhiteElephantGameData, callback: (events: WhiteElephantGameEventData[]) => any, createdAtOfLastDownloadedEvent?: number) {
  // Get game events from game
  let ref = getWhiteElephantGameEventsReference(thisGame.id);

  if (createdAtOfLastDownloadedEvent) {
    const query = ref.orderByChild('createdAtUnix').startAt(createdAtOfLastDownloadedEvent);
    query.on('value', async (snapshot) => {
      const data: {[key: string]: any} = snapshot.val();
      // console.log(`gameEvents -  data  ---> ${JSON.stringify(data)}`);
      const events: WhiteElephantGameEventData[] = [];
      Object.keys(data).forEach(key => {
        const gameData: WhiteElephantGameEventData = data[key] as WhiteElephantGameEventData;
        if (!gameData.giftIdToPlayerId) {
          gameData.giftIdToPlayerId = {};
        }
        if (!gameData.nextTakeableGiftIds) {
          gameData.nextTakeableGiftIds = {};
        }
        events.push(gameData);
      });
      await callback(events);
    });
  } else {
    ref.orderByChild('createdAtUnix').limitToLast(3).on('value', async (snapshot) => {
      // console.log(`gameEvents --  snapshot.val()  ---> ${JSON.stringify(snapshot.val())}`);
      const data: {[key: string]: any} = snapshot.val();
      // console.log(`gameEvents --  data  ---> ${JSON.stringify(data)}`);
      const events: WhiteElephantGameEventData[] = [];
      if (data) {
        Object.keys(data).forEach(key => {
          // console.log(`gameEvents -- key: ${key}`);
          // const theData = data[key] as WhiteElephantGameEventData;
          // console.log(`gameEvents -- data[key]: ${JSON.stringify(theData)}`);
          const gameData: WhiteElephantGameEventData = data[key] as WhiteElephantGameEventData;
          if (!gameData.giftIdToPlayerId) {
            gameData.giftIdToPlayerId = {};
          }
          if (!gameData.nextTakeableGiftIds) {
            gameData.nextTakeableGiftIds = {};
          }
          events.push(gameData);
        });
      }
      // console.log(`gameEvents --  events = ${JSON.stringify(events)}`);
      await callback(events);
    });
  }
}

export function resetWhiteElephantGameEvents(gameId: string) {
  let ref = getWhiteElephantGameEventsReference(gameId);
  ref.remove();
}


export function isNotFinalAction(lastGameEvent: WhiteElephantGameEventData) {
  return (lastGameEvent.canKeepBeNextAction || 
        lastGameEvent.canOpenBeNextAction || 
        lastGameEvent.canTakeBeNextAction);
}

export function convertDateToUnix(theDate: Date) {
  return parseInt((theDate.getTime() / 1000).toFixed(0));
}

export function convertUnixToDate(theUnix: number) {
  return new Date(theUnix * 1000);
}
