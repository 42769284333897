import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import 'firebase/storage';
import * as uuid from 'uuid';
import { WhiteElephantLocalStorageKey } from "./constants/dataConstants";
import { createUser, fetchPlayerByAuthId } from "./utils/fetchUserUtils";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DB,
  projectId: process.env.REACT_APP_PID,
  storageBucket: process.env.REACT_APP_SB,
  messagingSenderId: process.env.REACT_APP_SID,
  appId: process.env.REACT_APP_APPID,
  measurementId:process.env.REACT_APP_MID,
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const realtimeDb = firebase.database();
export const storageRef = firebase.storage().ref();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

const googleAuthPovider = new firebase.auth.GoogleAuthProvider();
googleAuthPovider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = async (signInCompleteCallback: (error?: string) => any) => {
  await auth.signInWithPopup(googleAuthPovider)
  .then(async result => {
    if (result.credential !== null) {
      // The signed-in user info.
      const user = result.user;
      if (user) {
        const authedUid = user.uid;
        const displayName = user.displayName;
        const userEmail = user.email;
        
        let userId = uuid.v4();
        // Attempt to see if user already exists
        await fetchPlayerByAuthId(user)
        .then(async (existingUser) => {
          let errorMessage: string | undefined = undefined;
          // If no existing User, create User
          if (!existingUser?.id) {
            const isCreateUserSuccessful = await createUser(user, userId, authedUid, displayName, userEmail);
            if (!isCreateUserSuccessful) {
              errorMessage = `We were unable to create your user. Try again.`;
            }
          }
          else {
            userId = existingUser.id;
          }
          localStorage.setItem(WhiteElephantLocalStorageKey.myUserId, userId);

          signInCompleteCallback(errorMessage);
        });
      } else {
        const errorMessage = `We were unable to get your user. Try again.`;
        signInCompleteCallback(errorMessage);
      }
    }
    else {
      const errorMessage = `We were unable to get your user credentials. Try again.`;
      signInCompleteCallback(errorMessage);
    }
  })
  .catch((error) => {
    // Handle Errors here.
    let errorMessage: string = `Oops. Something went wrong. Try again.`;
    // var errorCode = error.code;
    // var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    // var credential = error.credential;
    // Handle case where two accounts have same email
    if (error.code === 'auth/account-exists-with-different-credential') {
      errorMessage = `It appears that an account was already created with email: ${email}`;
      console.log(errorMessage);
    }

    signInCompleteCallback(errorMessage);
  });
}

const facebookPopupAuthProvider = new firebase.auth.FacebookAuthProvider();
facebookPopupAuthProvider.setCustomParameters({ display: 'popup' });
export const signInWithFacebook = async (signInCompleteCallback: (error?: string) => any) => {
  await auth.signInWithPopup(facebookPopupAuthProvider)
  .then(async result => {
    if (result.credential !== null) {
      // The signed-in user info.
      const user = result.user;
      if (user) {

        // Attempt to see if user already exists
        await fetchPlayerByAuthId(user)
        .then(async (existingUser) => {
          let errorMessage: string | undefined = undefined;
          let userId = uuid.v4();
          // If no existing User, create User
          if (!existingUser?.id) {
            const authedUid = user.uid;
            const displayName = user.displayName;
            const userEmail = user.email;
            const isCreateUserSuccessful = await createUser(user, userId, authedUid, displayName, userEmail);            
            if (!isCreateUserSuccessful) {
              errorMessage = `We were unable to create your user. Try again.`;
            }
          }
          else {
            userId = existingUser.id;
          }
          localStorage.setItem(WhiteElephantLocalStorageKey.myUserId, userId);
          
          signInCompleteCallback(errorMessage);
        });
      } else {
        const errorMessage = `We were unable to get your user. Try again.`;
        signInCompleteCallback(errorMessage);
      }
    }
    else {
      const errorMessage = `We were unable to get your user credentials. Try again.`;
      signInCompleteCallback(errorMessage);
    }
  })
  .catch((error) => {
    // Handle Errors here.
    let errorMessage: string = `Oops. Something went wrong. Try again.`;
    // var errorCode = error.code;
    // var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    // var credential = error.credential;
    // Handle case where two accounts have same email
    if (error.code === 'auth/account-exists-with-different-credential') {
      errorMessage = `It appears that an account was already created with email: ${email} linked to this Facebook account`;
      console.log(errorMessage);
    }

    signInCompleteCallback(errorMessage);
  });
}

export const signInWithGoogleRedirect = () => {
  auth.signInWithRedirect(googleAuthPovider);
}
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebookRedirect = () => {
  auth.signInWithRedirect(facebookAuthProvider);
}

export default firebase;