
import React from "react";
import { Button, Modal, Spinner, Alert } from "react-bootstrap";
import { UserData } from "../constants/dataConstants";
import { fetchPlayerByAuthId } from "../utils/fetchUserUtils";
import { AsyncFunction, awaitAsyncRequestWithRetry } from "../utils/retryUtils";
import './RemoveGameModal.css';

interface BaseComponentState {
  shouldShow: boolean;
  userData: UserData;
  isSavingUserData: boolean;
  shouldShowAlert: boolean;
  alertMessage: string;
}

interface BaseComponentProps {
  firebaseUser: any;
  shouldShow: boolean;
  gameId: string;
  onCloseCallback?: () => any;
  onGameRemovedCallback?: () => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class RemoveGameModal<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      shouldShow: this.props.shouldShow,
      isSavingUserData: false,
      shouldShowAlert: false,
      alertMessage: '',
    }
  }

  render() {
    return (
      <Modal show={this.state.shouldShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {`Are you sure you want to remove this game from your view?`}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleRemoveButtonClick} disabled={this.state.isSavingUserData}>
          {this.state.isSavingUserData && <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />}
            Remove
          </Button>
          <Button variant="secondary" onClick={this.handleClose} disabled={this.state.isSavingUserData}>
            Go back
          </Button>
          {this.state.shouldShowAlert &&
            <Alert variant="warning" onClose={() => this.setState({ shouldShowAlert: false })} dismissible>
              {this.state.alertMessage}
            </Alert>}
        </Modal.Footer>
      </Modal>
    );
  }

  private handleRemoveButtonClick = async () => {
    this.setState({isSavingUserData: true});
    const asycFunc: AsyncFunction<UserData | null> = { func: fetchPlayerByAuthId(this.props.firebaseUser) };
    const retryResponse = await awaitAsyncRequestWithRetry<UserData | null>(asycFunc);
    if(retryResponse.isSuccessful && retryResponse.result) {
      const myUserData = retryResponse.result;
      const newArrayOfGames: string[] = myUserData.myGameIds.filter((thisGameId) => (thisGameId !== this.props.gameId));
      myUserData.myGameIds = newArrayOfGames;

      this.saveUserInfo(myUserData);
    } else {
      this.setState({ shouldShowAlert: true, alertMessage: `Looks like we're having trouble removing this game from your view. Please try again.`, isSavingUserData: false });
    }
  }

  private handleClose = () => {
    this.setState({ shouldShow: false }, () => {
      if (this.props.onCloseCallback)
        this.props.onCloseCallback();
    });
  };

  private async saveUserInfo(userData: UserData) {
    const postToUserUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/user/?userId=${userData.id}`;
    const bearerToken = await this.props.firebaseUser.getIdToken();
    await fetch(postToUserUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
      body: JSON.stringify(userData)
    })
    .then(() => {
      this.setState({ 
        isSavingUserData: false,
        shouldShow: false,
      });
    })
    .catch((error) => {
      this.setState({ shouldShowAlert: true, alertMessage: `Looks like we're having trouble removing this game from your view. Please try again.`, isSavingUserData: false });
    });
  }
}