import React from 'react';
import { Button, Spinner, Form, InputGroup, Alert, ProgressBar, Row, Col, CardDeck, Card } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import moment from 'moment-timezone';
import firebase from 'firebase/app';
import 'bootstrap/dist/css/bootstrap.min.css';

import './HostEditGameRulesView.css';
import { WhiteElephantGameData, WhiteElephantGameFormat, WhiteElephantGameStatus, WhiteElephantLocalStorageKey } from '../constants/dataConstants';
import { storageRef } from '../firebase';
import { isOneOfDefaultGiftBoxes, randomGiftBoxImageId } from '../utils/fetchGiftSelectionUtils';
import { getDefaultImageSrc } from '../utils/imageUtils';
import { fetchWhiteElephantGameByGameCode } from '../utils/fetchWhiteElephantGameUtils';
import { updateGameStatus } from '../utils/StatusUtils';

interface BaseComponentState {
  currentGame: WhiteElephantGameData;
  shouldShowAlert: boolean,
  alertMessage: string,
  isSending: boolean,

  isGameImageIdSelected: {[imageId: string]: boolean},

  urlToFileImagesToUpload: {[url: string]: File},
  imagesUploaded: number;
  numOfImagesToUpload: number;
  imageUploadPercentage: number;
  isSavingGameImages: boolean;
}

interface BaseComponentProps {
  firebaseUser: any;
  myUserId: string;
  currentGame: WhiteElephantGameData;
  onStartGameRoomCallback: (savedWhiteElephantGameData: WhiteElephantGameData) => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class HostEditGameRulesView<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      currentGame: this.props.currentGame,
      shouldShowAlert: false,
      alertMessage: '',
      isSending: false,
      
      isGameImageIdSelected: {},

      urlToFileImagesToUpload: {},
      imagesUploaded: 0,
      numOfImagesToUpload: 0,
      imageUploadPercentage: 0,
      isSavingGameImages: false,
    }
    this.onDropGameImage = this.onDropGameImage.bind(this);
  }

  componentDidMount() {
    const isGameImageIdSelected: {[imageId: string]: boolean} = {};
    this.state.currentGame.imageIds?.forEach((imageId) => {
      isGameImageIdSelected[imageId] = true;
    });
    this.setState({ isGameImageIdSelected });
  }
  
  render() {
    const oldDate = new Date();
    const timeInMinutes = 15;
    const timeInFuture = new Date(oldDate.getTime() + timeInMinutes * 60000);

    return (
      <Form className="Full-Size">
        <Form.Group controlId="formGameName">
          <h3>
            {`Game Rules`}
          </h3>
          <Form.Label className="Form-Label">Game Name: </Form.Label>
          <Form.Control type="text" placeholder="Enter your game name" onChange={val => {
            const newGameState = this.state.currentGame;
            newGameState.gameName = val.target.value;
            this.setState({ currentGame: newGameState });
          }}
          defaultValue={this.state.currentGame.gameName.toString()} />
        </Form.Group>
        <Form.Group controlId="formGameTime">
          <Form.Label className="Form-Label">Game Time: </Form.Label>
          <Form.Control type="datetime-local" defaultValue={moment(this.state.currentGame.gameTime).format('yyyy-MM-DDTHH:mm')} onChange={val => {
            if (moment(val.target.value, "yyyy-MM-DDTHH:mm", true).isValid()) {
              const newGameState = this.state.currentGame;
              newGameState.gameTime = new Date(val.target.value);
              this.setState({ currentGame: newGameState });
            }
          }} />
        </Form.Group>
        <Form.Group controlId="formGameRulesPrice">
          <Row>
            <Col>
              <Form.Label className="Form-Label">Suggested Minimum Price: </Form.Label>
              <InputGroup className="Form-Money">
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <input aria-label="Amount (to the nearest dollar)" type="number" min="0" step="1" onChange={val => {
                  const newGameState = this.state.currentGame;
                  let newGameRules: {[key: string]: any;} = {};
                  if (newGameState.gameRules) newGameRules = newGameState.gameRules;
                  const dollarValue = Math.floor(val.target.valueAsNumber);
                  newGameRules['MinPrice'] = dollarValue;
                  newGameState.gameRules = newGameRules;
                  this.setState({ currentGame: newGameState });
                }} 
                defaultValue={this.state.currentGame.gameRules?.['MinPrice'] ? this.state.currentGame.gameRules?.['MinPrice'] : undefined} />
                <InputGroup.Append>
                  <InputGroup.Text>.00</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col>
              <Form.Label className="Form-Label">Suggested Maximum Price: </Form.Label>
              <InputGroup className="Form-Money-Right">
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <input aria-label="Amount (to the nearest dollar)" type="number" min="0" step="1" onChange={val => {
                  if (val.target.valueAsNumber) {
                    const newGameState = this.state.currentGame;
                    let newGameRules: {[key: string]: any;} = {};
                    if (newGameState.gameRules) newGameRules = newGameState.gameRules;
                    const dollarValue = Math.ceil(val.target.valueAsNumber);
                    newGameRules['MaxPrice'] = dollarValue;
                    newGameState.gameRules = newGameRules;
                    this.setState({ currentGame: newGameState });
                  }
                }}
                defaultValue={this.state.currentGame.gameRules?.['MaxPrice'] ? this.state.currentGame.gameRules?.['MaxPrice'] : undefined} />
                <InputGroup.Append>
                  <InputGroup.Text>.00</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
        </Form.Group>
        <Row>
          <div className="Form-Game-Title">Game Rules: </div>
        </Row>
        <Form.Group controlId="formGameRules">
          <Row>
            <Col>
              <CardDeck>
                <Card className="GameRules-Card" bg={this.state.currentGame.gameRules?.['Format'] === WhiteElephantGameFormat.classic ? 'primary' : 'light'}>
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                  <Card.Title>{WhiteElephantGameFormat.classic}</Card.Title>
                    <Card.Text>
                      Most Popular
                    </Card.Text>
                    {this.state.currentGame.gameRules?.['Format'] !== WhiteElephantGameFormat.classic && <Button variant="primary" onClick={() => {
                      const newGameState = this.state.currentGame;
                      let newGameRules: {[key: string]: any;} = {};
                      if (newGameState.gameRules) newGameRules = newGameState.gameRules;
                      newGameRules['Format'] = WhiteElephantGameFormat.classic;
                      newGameState.gameRules = newGameRules;
                      this.setState({ currentGame: newGameState });
                    }}>Select</Button>}
                  </Card.Body>
                </Card>
                <Card className="GameRules-Card" bg={this.state.currentGame.gameRules?.['Format'] === WhiteElephantGameFormat.single_steal ? 'primary' : 'light'}>
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                  <Card.Title>{WhiteElephantGameFormat.single_steal}</Card.Title>
                    <Card.Text>
                      Only 1 steal per round
                    </Card.Text>
                    {this.state.currentGame.gameRules?.['Format'] !== WhiteElephantGameFormat.single_steal && <Button variant="primary" onClick={() => {
                      const newGameState = this.state.currentGame;
                      let newGameRules: {[key: string]: any;} = {};
                      if (newGameState.gameRules) newGameRules = newGameState.gameRules;
                      newGameRules['Format'] = WhiteElephantGameFormat.single_steal;
                      newGameState.gameRules = newGameRules;
                      this.setState({ currentGame: newGameState });
                    }}>Select</Button>}
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="formGameImages" className="FullWidth">
          <div className={"PaddingBottom-2"}>Choose a game image or upload your own game image</div>
          <div className={"FullWidth"}>
            {this.renderImageThumbnailSelector(this.props.currentGame?.imageIds ?? [], this.state.isGameImageIdSelected, 'game image')}
          </div>
        </Form.Group>
        <Form.Group controlId="formImageUploadGame">
          <ImageUploader
            withIcon={true}
            buttonText="Add your own game image"
            onChange={this.onDropGameImage}
            imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
            withPreview={true}
          />
        </Form.Group>
        <Form.Group controlId="formSharing" className="GameCode-Section">
          <h3>Share this link with your party!</h3>
          <div className={"GameCode-Text FullWidth"}>https://elephant-giftexchange.com/joinWithCode/{this.state.currentGame.gameCode}</div>
          <h4>or share this game code</h4>
          <div className="GameCode-Text">{this.state.currentGame.gameCode}</div>
        </Form.Group>
        <div>
          <Button variant='outline-primary' className="Form-Button" onClick={async () => {
            if(this.validateGameRules()) {
              await this.updateGame(this.state.currentGame);
            } else {
              this.setState({ shouldShowAlert: true });
            }
          }} disabled={this.state.isSending}>
            {this.state.isSending && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
            Save Changes
          </Button>
          {this.state.currentGame.gameTime < timeInFuture && 
            <Button variant='outline-primary' className={"Form-Button MarginLeft-2"} onClick={async () => {
              await this.updateGame(this.state.currentGame).then(async () => {
                updateGameStatus(this.state.currentGame.id, WhiteElephantGameStatus.game_room_open, async () => {
                  // TODO: Check all images saved before moving forward
                  await this.props.onStartGameRoomCallback(this.state.currentGame);
                });
              });
              // TODO: Go to Game View
            }} disabled={this.state.isSending}>
              {this.state.isSending && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
              Open the game room!
            </Button>}
        </div>
        {this.state.imagesUploaded < this.state.numOfImagesToUpload &&
          <div>
            <div>
              Uploading image {this.state.imagesUploaded} of {this.state.numOfImagesToUpload}...
            </div>
            <div>
              <ProgressBar now={this.state.imageUploadPercentage} />
            </div>
          </div>}
        {this.state.shouldShowAlert && 
        <Alert variant="danger" onClose={() => this.setState({ shouldShowAlert: false })} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>
            {this.state.alertMessage}
          </p>
        </Alert>}
      </Form>
    );
  }

  renderImageThumbnailSelector(imageIds: string[], isImageIdSelected: {[imageId: string]: boolean}, categoryOfImages: string) {
    this.downloadImages(imageIds);
    imageIds = imageIds.filter((imageId) => !isOneOfDefaultGiftBoxes(imageId));
    imageIds = [...imageIds, 'giftBox1', 'giftBox2', 'giftBox3', 'giftBox4'];
    return (
      <div className="FullWidth">
        <div className="ScrollX">
        <div className="imageDiv">
        {imageIds.map((imageId, i) => {
          let classNameValue = "ThumbnailSize";
          if(isImageIdSelected[imageId]) {
            classNameValue += " BorderBlue"
          }
          if(isOneOfDefaultGiftBoxes(imageId)) {
            return (
              <img id={'default-'+imageId} key={'default-'+imageId} alt={`${categoryOfImages} ${i}`} className={classNameValue} src={getDefaultImageSrc(imageId)} onClick={() => {this.handleImageSelectClick(imageId)}} />
            );
          } else {
            return (
              <img id={imageId} key={imageId} alt={`${categoryOfImages} ${i}`} className={classNameValue} onClick={() => {this.handleImageSelectClick(imageId)}} />
            );
          }
        })}
        </div>
        </div>
      </div>
    );
  }
  handleImageSelectClick = (imageId: string) => {
    const isImageIdSelected: {[imageId: string]: boolean} = this.state.isGameImageIdSelected;
    isImageIdSelected[imageId] = !!!this.state.isGameImageIdSelected[imageId];
    this.setState({ isGameImageIdSelected: isImageIdSelected });
  }
  renderImageThumbnails(imageIds: string[], categoryOfImages: string) {
    this.downloadImages(imageIds);
    return (
      <div className="FullWidth">
        <div className="ScrollX">
        <div className="imageDiv">
        {imageIds.map((imageId, i) => {
          return (
            <img id={imageId} key={imageId} alt={`${categoryOfImages} ${i}`} className={"ThumbnailSize"} />
          );
        })}
        </div>
        </div>
      </div>
    );
  }

  downloadImages(imageIds: string[]) {
    imageIds.forEach((imageId: string) => {
      if(!isOneOfDefaultGiftBoxes(imageId) && imageId.indexOf('m.media-amazon.com') < 0) {
        const imageRef = storageRef.child(imageId);
        imageRef.getDownloadURL().then((url) => {
          // inserted into an <img> element:
          const img = document.getElementById(imageId) as HTMLImageElement;
          if (img) {
            img.src = url;
          }
        });
      }
    });
  }

  onDropGameImage(pictureFiles: File[], pictureDataURLs: string[]) {
    const urlToFileImagesToUpload: {[url: string]: File} = {};
    pictureDataURLs.forEach((url, i) => {
      urlToFileImagesToUpload[url] = pictureFiles[i];
    });
    this.setState({
      urlToFileImagesToUpload
    });
  }


  // Helper functions for Game Settings
  private validateGameRules() {
    if (this.state.currentGame.gameRules?.['MinPrice'] > this.state.currentGame.gameRules?.['MaxPrice']) {
      this.setState({ alertMessage: 'Minimum Price cannot be greater than Maximum Price. Please choose a Minimum Price that is less than the Maximum Price.' });
      return false;
    } else if (this.state.currentGame.gameName.length < 3) {
      this.setState({ alertMessage: 'Please enter a valid game name. (At least 3 characters)' });
      return false;
    }
    return true;
  }
  private async updateGame(newGameData: WhiteElephantGameData) {
    // console.log(`Update Game with : ${JSON.stringify(newGameData)}`);
    const isGameImageIdSelected: {[imageId: string]: boolean}  = this.state.isGameImageIdSelected;
    let gameImageId: string[] = [];
    if (this.state.urlToFileImagesToUpload) {
      const gameImageUrls = Object.keys(this.state.urlToFileImagesToUpload);
      this.setState({ 
        isSending: true,
        imagesUploaded: 0,
        numOfImagesToUpload: gameImageUrls.length,
      });
      // Save Images
      gameImageUrls.forEach((url) => {
        gameImageId.push(this.uploadImage(this.state.urlToFileImagesToUpload[url], url));
      });
    }
    const selectedGameImageIds: string[] = Object.keys(isGameImageIdSelected);
    if(selectedGameImageIds.length === 0) {
      if ( gameImageId.length === 0) {
        // TODO: Have a separate set of images for Game Images
        gameImageId = [randomGiftBoxImageId()];
      }
    } else {
      selectedGameImageIds.forEach((imageId) => {
        if(!!isGameImageIdSelected[imageId]) {
          gameImageId.push(imageId);
        }
      });
    }
    newGameData.imageIds = gameImageId;
    
    const targetUrl: string = `https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/whiteElephantGame/?gameId=${newGameData.id}`;

    this.setState({ 
      isSending: true,
    })

    const currentGameState = await fetchWhiteElephantGameByGameCode(this.props.firebaseUser, newGameData.gameCode);
    if (currentGameState) {
      newGameData.players = currentGameState.players;
    }

    const bearerToken = await this.props.firebaseUser?.getIdToken();
    fetch(targetUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization':`Bearer ${bearerToken}`,
      },
      body: JSON.stringify(newGameData),
    })
    .then(async () => {
      this.setState({ 
        isSending: false,
        currentGame: newGameData,
      });
      localStorage.setItem(WhiteElephantLocalStorageKey.currentGame, JSON.stringify(newGameData));
    })
    .catch((error) => {
      this.setState({ 
        isSending: false,
      });
    });
  }

  // Image Helper Functions
  private uploadImage(pictureFile: File, pictureDataURL: string) {
    // Create reference to image
    const myGameImageCollection = 'GameImage/' + this.props.myUserId;
    // Parse out relevant details from pictureDataURL
    // data:image/jpeg;name=modern_house.jpg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gIcSUNDX1BST0...
    const splitBySemiColon = pictureDataURL.split(';');
    const dataType = splitBySemiColon[0].split(':')[1];
    const fileName = splitBySemiColon[1].split('=')[1];
    
    const imageRef = storageRef.child(myGameImageCollection).child(fileName);
    const uploadTask = imageRef.put(pictureFile, { contentType: dataType });

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', (snapshot) =>{
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      this.setState({ imageUploadPercentage: progress });
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
      }
    }, function(error) {
      // Handle unsuccessful uploads
    }, () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL()
      .then((downloadURL) => {
        console.log('File available at', downloadURL);
        const imagesUploaded = this.state.imagesUploaded + 1;
        if (imagesUploaded === this.state.numOfImagesToUpload) {
          // All images uploaded successfully
          this.onAllImageSavedCallback();
        }
  
        this.setState({ imagesUploaded });
      });
    });
    return myGameImageCollection + '/' + fileName;
  }

  private onAllImageSavedCallback() {
    
  }
}