
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './DynamicTopBottomView.css';

interface BaseComponentProps {
  renderTopHalf: any;
  renderBottomHalf: any;
}

export type ComponentState<ChildState = {}> = ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class DynamicTopBottomView<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {

  render() {
    return (
      <div className="DynamicTBV-Full-Size-Screen">
        <div className="Columns-Or-Rows">
          <div className="View-One">{this.props.renderTopHalf}</div>
          <div className="View-Two">{this.props.renderBottomHalf}</div>
        </div>
      </div>
    );
  }
}