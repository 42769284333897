
import React from "react";
import { Button, InputGroup, Spinner } from "react-bootstrap";
import { ChatMessageData, UserData } from "../constants/dataConstants";
import { downloadChatMessagesOnce, downloadNewChatMessages, sendChatMessage } from "../utils/chatMessageUtils";

import './ChatComponent.css';

interface BaseComponentState {
  downloadedChatMessages: ChatMessageData[];
  createdAtOfOldestDownloadedEvent?: number;
  createdAtOfNewestDownloadedEvent?: number;
  chatMessage: string;
  isSendingChat: boolean;
  isLoadingChatMessages: boolean;
}

interface BaseComponentProps {
  user: any;
  gameId: string;
  gameName: string;
  myUserId: string;
  playerIdToPlayer: {[key: string]: UserData};
  onNewMessageCallback?: () => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class ChatComponent<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      chatMessage: '',
      downloadedChatMessages: [],
      isSendingChat: false,
      isLoadingChatMessages: false,
    }
  }

  async componentDidMount () {
    this.setState({ isLoadingChatMessages: true }, async () => {
      await downloadChatMessagesOnce(this.props.gameId, this.handleChatMessageDownload, this.state.createdAtOfOldestDownloadedEvent)
      .then(() => {
        downloadNewChatMessages(this.props.gameId, this.handleChatMessageDownload, this.state.createdAtOfNewestDownloadedEvent);
      });
    });
  }

  render() {
    return (
      <div className="ChatBox-Container">
        <div className="ChatBox-Top">
          {this.props.gameName}
        </div>
        <div className="ChatBox" id="ChatBox">
          {this.state.downloadedChatMessages && 
          this.state.downloadedChatMessages.map((chatMessageData, i) => {
            return (
              <div key={`${chatMessageData.id}-${i}`}>
              {(chatMessageData.playerId === this.props.myUserId) ? 
              (
                // My chats
                <div className="MyChats-Row">
                  <div className="MyChats-RowSection">
                    {chatMessageData.content}
                  </div>
                </div>
              ) : 
              (
                // Other player's chats
                <div className="OtherChats-Row ">
                  <div className="OtherChats-RowSection">  
                    {this.props.playerIdToPlayer[chatMessageData.playerId]?.name ? this.props.playerIdToPlayer[chatMessageData.playerId]?.name : 
                    this.props.playerIdToPlayer[chatMessageData.playerId]?.email}
                    {`: ${chatMessageData.content}`}
                  </div>
                </div>
              )}
            </div>
            )
          })}
        </div>
        <InputGroup className="Chat-InputGroup" id="ChatBar">
          <input aria-label="Send a message" type="text" placeholder="Send a message to the party" className="Chat-Input" 
          onChange={val => {
            this.setState({ chatMessage: val.target.value });
          }}
          value={this.state.chatMessage}
          onKeyDown={this.handleKeyDown} />
          <InputGroup.Append>
            <Button variant="primary" className="Chat-SendButton" onClick={() => this.sendChatMessage(this.state.chatMessage)} disabled={this.state.isSendingChat}>
              {this.state.isSendingChat && <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />}
              {!this.state.isSendingChat && `Send`}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
    );
  }


  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !this.state.isSendingChat) {
      // console.log('Enter was pressed');
      this.sendChatMessage(this.state.chatMessage);
      this.setState({ chatMessage: '' });
    }
  }

  private sendChatMessage = (chatMessage: string) => {
    if(chatMessage && !this.state.isSendingChat) {
      this.setState({ isSendingChat: true });
      chatMessage = '' + chatMessage.substring(0,300);
      // Send Chat
      sendChatMessage(this.props.gameId, this.props.myUserId, chatMessage, this.callbackOnSuccessfulChatSent);
    }
  }

  private callbackOnSuccessfulChatSent = (ChatMessageSent: ChatMessageData) => {

    this.setState({ 
      isSendingChat: false, chatMessage: '',
    }, () => {
      const element = document.getElementById("ChatBar");
      element?.scrollIntoView({behavior: 'auto'});
    });
  }

  private handleChatMessageDownload = (downloadedChatMessages: ChatMessageData[]) => {
    // console.log(`downloadedChatMessages: ${JSON.stringify(downloadedChatMessages)}`);
    let newDownloadedChatMessages = this.state.downloadedChatMessages;  
    // Sort by time; Newest to Oldest
    const sortedNewChatMessages = downloadedChatMessages.sort((a,b) => a.createdAtUnix > b.createdAtUnix ? -1 : 1);

    if(newDownloadedChatMessages.length ===  1 && newDownloadedChatMessages[0].id === sortedNewChatMessages[(sortedNewChatMessages.length-1)].id) {
      this.setState({ isLoadingChatMessages: false, downloadedChatMessages: sortedNewChatMessages, createdAtOfOldestDownloadedEvent: sortedNewChatMessages[0]?.createdAtUnix, createdAtOfNewestDownloadedEvent: sortedNewChatMessages[sortedNewChatMessages.length-1]?.createdAtUnix }, () => {
        if (this.props.onNewMessageCallback) {
          this.props.onNewMessageCallback();
        }
      });
    } else {
      newDownloadedChatMessages.push(...sortedNewChatMessages);
      
      // To make things more efficient, only display up to 100 messages
      if (newDownloadedChatMessages.length > 100) {
        const lengthOfMessages = newDownloadedChatMessages.length;
        newDownloadedChatMessages = newDownloadedChatMessages.slice(lengthOfMessages-100, lengthOfMessages);
      }
      // Filter out duplicates
      newDownloadedChatMessages = newDownloadedChatMessages.filter((value,index,array)=>array.findIndex(t=>(t.id === value.id))===index);
      // Sort by time; Newest to Oldest
      const sortedChats = newDownloadedChatMessages.sort((a,b) => a.createdAtUnix > b.createdAtUnix ? -1 : 1);

      this.setState({ isLoadingChatMessages: false, downloadedChatMessages: sortedChats, createdAtOfOldestDownloadedEvent: sortedChats[0]?.createdAtUnix, createdAtOfNewestDownloadedEvent: sortedChats[sortedChats.length-1]?.createdAtUnix }, () => {
        if (this.props.onNewMessageCallback) {
          this.props.onNewMessageCallback();
        }
      });
    }
  }

}