import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import * as uuid from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';

import './HomePage.css';
import { auth, signInWithGoogleRedirect, signInWithFacebookRedirect } from '../firebase';
import { WHITE_ELEPHANT_GAME } from '../constants/routes';
import wrapGiftVideo1_landscape from '../assets/video/wrap-gift-1.mp4';
import wrapGiftVideo1_portrait from '../assets/video/wrap-gift-1-4x5-vertical.mp4';
import logo from '../assets/logo.png';
import downArrow from '../assets/icons/down-chevron.png';
import googleLogo from '../assets/icons/google-logo.png';
import facebookLogo from '../assets/icons/facebook-logo.png';
import { UserData, WhiteElephantGameData, WhiteElephantLocalStorageKey } from '../constants/dataConstants';
import EditAccountModal from './EditAccountModal';
import { createUser, fetchPlayerByAuthId, isUserComplete } from '../utils/fetchUserUtils';
import InfoModal from '../modals/InfoModal';
import { fetchWhiteElephantGameByGameCode, joinGame } from '../utils/fetchWhiteElephantGameUtils';
import { AsyncFunction, awaitAsyncRequestWithRetry } from '../utils/retryUtils';

interface BaseComponentState {
  firebaseUser: any;
  isLoadingVideo1: boolean;
  isLoadingVideo2: boolean;
  isSending: boolean;
  goToWhiteElephantGame: boolean;
  codeInput: string;
  isAllowed: boolean;

  myUserData?: UserData;
  shouldShowEditUserData: boolean;
  isCurrentlyLoggingIn: boolean;

  shouldShowInfoModal: boolean;
  infoModalTitle: string;
  infoModalBody: string;
  
  shouldShowLoginInfoModal: boolean;
  loginModalTitle: string;
  loginModalBody: string;

  shouldShowJoinIssueModal: boolean;
  joinIssueModalTitle: string;
  joinIssueModalBody: string;
}

interface BaseComponentProps {
  joinWithCode?: string;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class HomePage<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      firebaseUser: null,
      isLoadingVideo1: true,
      isLoadingVideo2: true,
      goToWhiteElephantGame: false,
      isSending: false,
      codeInput: '',
      isAllowed: true,

      shouldShowEditUserData: false,
      isCurrentlyLoggingIn: false,

      shouldShowInfoModal: false,
      infoModalTitle: '',
      infoModalBody: '',

      shouldShowLoginInfoModal: false,
      loginModalTitle: '',
      loginModalBody: '',

      shouldShowJoinIssueModal: false,
      joinIssueModalTitle: '',
      joinIssueModalBody: '',
    };
  }

  private unsubscribeFromAuth: any = null;

  componentDidMount() {
    if ( !!this.props.joinWithCode ) {
      this.setState({ shouldShowInfoModal: true, infoModalTitle: `You've been invited!`, infoModalBody: `Sign in below to join the party.` });
    }
    this.unsubscribeFromAuth = auth.onAuthStateChanged(user => {
      if (user) {
        // User signed in
        this.setState({ firebaseUser: user, shouldShowInfoModal: false, isCurrentlyLoggingIn: true }, 
          async () => {
            // Attempt to see if user already exists
            const asycFunc: AsyncFunction<UserData | null> = { func: fetchPlayerByAuthId(user) };
            const retryResponse = await awaitAsyncRequestWithRetry<UserData | null>(asycFunc);
            // console.log(`retryResponse: ${JSON.stringify(retryResponse)}`);
            if(retryResponse.isSuccessful && retryResponse.result) {
              // My User Data is found
              const myUserData: UserData = retryResponse.result;
              localStorage.setItem(WhiteElephantLocalStorageKey.myUserId, myUserData.id);
              this.setState({ myUserData });
              this.finishSignInSequence();
            } else {
              // If no existing User, create User
              const userId = uuid.v4();
              const authedUid = user.uid;
              const displayName = user.displayName;
              const userEmail = user.email;
              const isCreateUserSuccessful = await createUser(user, userId, authedUid, displayName, userEmail);
              if (isCreateUserSuccessful) {
                // Created user successfully
                localStorage.setItem(WhiteElephantLocalStorageKey.myUserId, userId);
                const fetchPlayerAsycFunc: AsyncFunction<UserData | null> = { func: fetchPlayerByAuthId(user) };
                const fetchPlayerResponse = await awaitAsyncRequestWithRetry<UserData | null>(fetchPlayerAsycFunc);
                // console.log(`retryResponse: ${JSON.stringify(retryResponse)}`);
                if(fetchPlayerResponse.isSuccessful && fetchPlayerResponse.result) {
                  // My User Data is found
                  const myUserData: UserData = fetchPlayerResponse.result;
                  this.setState({ myUserData }, () => {
                    this.finishSignInSequence();
                  });
                } else {
                  this.setState({ shouldShowLoginInfoModal: true, loginModalTitle: `Login Issue`, loginModalBody: `We were unable to finish creating your user. Try again.`, isCurrentlyLoggingIn: false });
                }
              } else {
                this.setState({ shouldShowLoginInfoModal: true, loginModalTitle: `Login Issue`, loginModalBody: `We were unable to create your user. Try again.`, isCurrentlyLoggingIn: false });
              }
            }
          });
      }
    });
    auth.getRedirectResult()
    .then(() => {
      // console.log(`this.state: ${JSON.stringify(this.state)}`);
    })
    .catch((error) => {
      // Handle Errors here.
      let errorMessage: string = `Oops. Something went wrong. Try again.`;
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      // Handle case where two accounts have same email
      if (error.code === 'auth/account-exists-with-different-credential') {
        errorMessage = `It appears that an account was already created with email: ${email} linked to this Google or Facebook account`;
      }
      this.setState({ shouldShowLoginInfoModal: true, loginModalTitle: `Login Issue`, loginModalBody: errorMessage, isCurrentlyLoggingIn: false });
    });
  }


  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    if (this.state.goToWhiteElephantGame) {
      return <Redirect push to={WHITE_ELEPHANT_GAME} />;
    }
    return (
      <div className="HomePage">
        {this.state.shouldShowInfoModal && 
        <InfoModal shouldShow={this.state.shouldShowInfoModal} title={this.state.infoModalTitle} bodyMessage={this.state.infoModalBody} onClose={() => this.setState({ shouldShowInfoModal: false })} />}
        {this.renderViewOne()}
        {this.renderViewTwo()}
      </div>
    );
  }

  private renderViewOne() {
    return (
      <div className="ViewOne-Container">
        { this.state.isCurrentlyLoggingIn && 
          <div className={"Content Center TopLoadingOverlay"}>
          <div className={"Center"}>
            <div>
              <Spinner animation="border" role="status">
              </Spinner>
              </div>
                {'Trying to log in... '}
              <div>
            </div>
          </div>
        </div>}
        <div className="Video-Container">
          <video src={wrapGiftVideo1_landscape} loop autoPlay muted 
          preload={'auto'} onLoad={() => this.setState({isLoadingVideo1: false})}
          className={"Video-top displayOn-landscape"} />
          <video src={wrapGiftVideo1_portrait} loop autoPlay muted 
          preload={'auto'} onLoad={() => this.setState({isLoadingVideo2: false})}
          className={"Video-top displayOn-portrait"} />
          <img src={logo} alt='Elephant Gift Exchange' className="Logo-top" />
          <img src={downArrow} alt='DownArrow' className="bottom" onClick={() => {
              const element = document.getElementById("ViewTwo");
              element?.scrollIntoView({behavior: 'smooth'});
          }} />
        </div>
      </div>
    );
  }

  private renderViewTwo() {
    return (
      <div className={"Content ViewTwo"} id="ViewTwo">
        <div className="image-background">
          <div className="HomePage-body">
            {this.state.shouldShowLoginInfoModal && 
            <InfoModal shouldShow={this.state.shouldShowLoginInfoModal} title={this.state.loginModalTitle} bodyMessage={this.state.loginModalBody} onClose={ async () => {
              await auth.signOut().then(() => {
                localStorage.removeItem(WhiteElephantLocalStorageKey.myUserId);
                this.setState({ firebaseUser: null, shouldShowLoginInfoModal: false });
              });
            }} />}
            {this.state.shouldShowJoinIssueModal && 
            <InfoModal shouldShow={this.state.shouldShowJoinIssueModal} title={this.state.joinIssueModalTitle} bodyMessage={this.state.joinIssueModalBody} onClose={() => {
              this.infoModalCloseAndGoToNext();
            }} />}
            {this.renderUserAuthComponents()}
          </div>
        </div>
      </div>
    );
  }

  private infoModalCloseAndGoToNext = () => {
    if (!!this.state.firebaseUser) {
      // console.log(`firebaseUser exists`);
      this.setState({ shouldShowJoinIssueModal: false, goToWhiteElephantGame: true }, () => {
        // console.log(` -- this.state.goToWhiteElephantGame: ${this.state.goToWhiteElephantGame}`);
        <Redirect push to={WHITE_ELEPHANT_GAME} />;
      });
    } else {
      // console.log(`no firebaseUser exists`);
      this.setState({ shouldShowJoinIssueModal: false });
    }
  }

  private renderUserAuthComponents() {
    return (
      <div className="FullScreen">
        <div className="SignIn-Base">
          <div>
          <div className="OneThird-Top">
            <img src={logo} alt='Elephant Gift Exchange' className="Logo-Center" />
          </div>
          <div className="Bottom">
            <div>
              <button onClick={async () => {
                this.setState({ isCurrentlyLoggingIn: true });
                signInWithGoogleRedirect();
                }} className="Google-Button">
                <img src={googleLogo} alt='google-logo' className="Login-Logo-Google" />
                  Continue with Google
              </button>
            </div>
            <div>
              <button onClick={async () => {
                this.setState({ isCurrentlyLoggingIn: true });
                signInWithFacebookRedirect();
              }} className="Facebook-Button">
                <img src={facebookLogo} alt='facebook-logo' className="Login-Logo-Facebook" />
                  Continue with Facebook
              </button>
            </div>
          </div>
          </div>
          { this.state.isCurrentlyLoggingIn && 
            <div className={"Content Center"}>
            <div className={"Center"}>
              <div>
                <Spinner animation="border" role="status">
                </Spinner>
                </div>
                  {'Trying to log in... '}
                <div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }

  private async attemptToJoinGameWithCode(gameCode: string) {
    if (this.state.firebaseUser && !!this.state.myUserData ) {
      // User was invited to join with a code
      const whiteElephantGame: WhiteElephantGameData | undefined = await fetchWhiteElephantGameByGameCode(this.state.firebaseUser, gameCode);
      if (whiteElephantGame) {
        // Valid game code
        const resultingUserData = await joinGame(this.state.firebaseUser, this.state.myUserData!, whiteElephantGame);
        if (!resultingUserData) {
          // Join Game unsuccessful
          this.setState({ shouldShowJoinIssueModal: true, joinIssueModalTitle: `Join Issue`, joinIssueModalBody: `We were unable to join this game. Try refreshing this page or asking for the game code and typing it in.` });
          return false;
        } else {
          this.setState({ isCurrentlyLoggingIn: false, goToWhiteElephantGame: true });
          return true;
        }
      } else {
        // Invalid game code
        this.setState({ shouldShowJoinIssueModal: true, joinIssueModalTitle: `Invalid Game Code`, joinIssueModalBody: `Oops! Looks like we couldn't find the game associated to this link.` });
        return false;
      }
    }
    return false;
  }

  private async finishSignInSequence() {
    if( !!this.props.joinWithCode ) {
      // User is trying to join with a join code url
      await this.attemptToJoinGameWithCode(this.props.joinWithCode!);
    } else {
      // User is just logging in normally on the homepage
      this.setState({ isCurrentlyLoggingIn: false, goToWhiteElephantGame: true });
    }
  }

  private renderLoggedIn() {
    return (
      <div>
        {(this.state.myUserData && (!isUserComplete(this.state.myUserData) || this.state.shouldShowEditUserData)) &&
          <EditAccountModal firebaseUser={this.state.firebaseUser} shouldShow={true} userData={this.state.myUserData!} isUserIncomplete={!isUserComplete(this.state.myUserData)} />}
        <div className="Padding-Top-20">
          <img src={this.state.firebaseUser.photoURL} alt='photoUrl' />
        </div>
        <div>Name: {this.state.firebaseUser.displayName}</div>
        <div>Email: {this.state.firebaseUser.email}</div>

        {
          this.state.firebaseUser && this.state.isAllowed && 
          <div>
            <div className={"Margin-Top-20 Center-Horizontal"}>
              <Button variant='outline-primary' className={"Button Center-Horizontal Width-80-Percent"}>
              <Link to={WHITE_ELEPHANT_GAME}>Let's play a White Elephant Game!</Link>
              </Button>
            </div>
          </div>
        }
        <div className="Margin-Top-20">
          <Button variant='outline-primary' onClick={async () => {
            await auth.signOut().then(() => {
              localStorage.removeItem(WhiteElephantLocalStorageKey.myUserId);
              localStorage.removeItem('ElephantGiftExchange-isAllowed');
              this.setState({ firebaseUser: null });
            });
          }}>LOG OUT</Button>
        </div>

      </div>
    );
  }


  private async checkAccessCode(codeInput?: string) {
    if(codeInput) {
      const targetUrl: string = 'https://us-central1-elephant-giftexchange.cloudfunctions.net/webApi/api/v1/allowAccess/';
      this.setState({ 
        isSending: true,
      })
      const bearerToken = await this.state.firebaseUser?.getIdToken();
      await fetch(targetUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${bearerToken}`,
        },
        body: JSON.stringify({
          accessCode: codeInput,
        })
      })
      .then(async response => {
        const responseBody = await response.text();
        // console.log(`checkAccessCode responseBody: ${JSON.stringify(responseBody)}`);
        if (response.ok) {
          const responsePayload = JSON.parse(responseBody);
          if (responsePayload.status === 'Access Granted') {
            // console.log('Access Granted');
            this.setState({ 
              isAllowed: true,
              isSending: false,
            }, () => {
              localStorage.setItem('ElephantGiftExchange-isAllowed', codeInput);
              this.setState({goToWhiteElephantGame: true});
            })
          }
        }
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        this.setState({ 
          isSending: false,
        }, () => {
          localStorage.removeItem('ElephantGiftExchange-isAllowed');
        })
      });
    }
  }
}