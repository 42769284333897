export function isValidHttpUrl(urlString: string) {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function generateMyAmazonUrl(url: string) {
  if (url.indexOf('amazon.com') >= 0 && url.indexOf('tag=elephantgifte-20') < 0) {
    if (url.indexOf('ref=') > 0) {
      const urlParts = url.split('ref=');
      let urlFirstPart = urlParts[0];
      if (urlFirstPart.charAt(urlFirstPart.length-1) !== '/') {
        urlFirstPart += '/';
      }
      return urlFirstPart + 'ref=nosim?tag=elephantgifte-20';
    } else {
      const urlLength = url.length;
      if (urlLength > 0 && url[urlLength-1] !== '/') {
        return url + '/?tag=elephantgifte-20';
      } else {
        return url + '?tag=elephantgifte-20';
      }
    }
  } else {
    return url;
  }
}
