
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { gifType, WhiteElephantGameAction } from "../constants/dataConstants";
import './ActionGiphyModal.css';

interface BaseComponentState {
  shouldShow: boolean;
}

interface BaseComponentProps {
  shouldShow: boolean;
  actionNum: number;
  action: string;
  gifType: gifType;
  playerName: string;
  giftName: string;
  takenFromPlayerName?: string;
  handleCloseCallback: () => any;
}

export type ComponentState<ChildState = {}> = BaseComponentState & ChildState;
export type ComponentProps<ChildProps = {}> = BaseComponentProps & ChildProps;

export default class ActionGifphyModal<
  Props extends ComponentProps = ComponentProps,
  State extends ComponentState = ComponentState
> extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      shouldShow: this.props.shouldShow,
    }
  }

  render() {
    let titleString = '';
    titleString += this.props.playerName;
    if (this.props.action === WhiteElephantGameAction.keep) {
      titleString += ' is keeping the gift. (' + this.props.giftName + ')';
    } else if (this.props.action === WhiteElephantGameAction.open) {
      titleString += ' opened a new gift: ' + this.props.giftName;
    } else if (this.props.action === WhiteElephantGameAction.take) {
      titleString += ' took a gift';
      if (this.props.takenFromPlayerName) {
        titleString += ' from ' + this.props.takenFromPlayerName; 
      }
      titleString += '. (' + this.props.giftName + ')';
    }
    return (
      <Modal show={this.state.shouldShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{titleString}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderGif()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // TODO: Automatically hide modal after 7 seconds

  private handleClose = () => {
    this.setState({ shouldShow: false }, () => {
      this.props.handleCloseCallback();
    });
  };

  private renderGif() {
    const type = this.props.gifType;
    let gifSrc = "https://media.giphy.com/media/kBHMtrZaLXHTyMELuV/giphy.gif";
    const unwrapGifs: string[] = [
      "https://media.giphy.com/media/l4EoZtu36syRRZzG0/giphy.gif", 
      "https://media.giphy.com/media/VJrkdDAg4SYFSxlXSD/giphy.gif", 
      "https://media.giphy.com/media/l2RntnIgHrKkUyYO4/giphy.gif", 
      "https://media.giphy.com/media/ZCHNoZLaIlLxJY8kHd/giphy.gif", 
      "https://media.giphy.com/media/zy6cfH1ZelttK/giphy.gif",
      "https://media.giphy.com/media/GUhxglEk24PqTBPylw/giphy.gif",
      "https://media.giphy.com/media/s68tXepF69WZq/giphy.gif",
    ];
    const takeGifs: string[] = [
      "https://media.giphy.com/media/3oKIP6cQ7KAkr8scPS/giphy.gif", 
      "https://media.giphy.com/media/ZXkBwDv8jruVWaITGe/giphy.gif", 
      "https://media.giphy.com/media/YrAqHmhmFATFdeGpKJ/giphy.gif", 
      "https://media.giphy.com/media/1ugExf74fLoyI/giphy.gif", 
      "https://media.giphy.com/media/Z9DDTxtG7GFTH6iyfo/giphy.gif",
      "https://media.giphy.com/media/l0MYN7mdvcZBBpEly/giphy.gif",
      "https://media.giphy.com/media/ecK2E5gEtmkbsmGmFK/giphy.gif",
    ];
    const keepGifs: string[] = [
      "https://media.giphy.com/media/39hoXKE2isn6nrwKos/giphy.gif", 
      "https://media.giphy.com/media/322FvxfciE8UsYvILG/giphy.gif", 
      "https://media.giphy.com/media/cm5lcHtT4QW8XCL42S/giphy.gif", 
      "https://media.giphy.com/media/3o6ZtpvPW6fqxkE1xu/giphy.gif", 
      "https://media.giphy.com/media/RlrfCHMNnCSsUd3kJZ/giphy.gif",
      "https://media.giphy.com/media/l3vRabxJJKmSmlIAw/giphy.gif",
    ];
    if (type === gifType.unwrap) {
      const gifNum = ( this.props.actionNum % unwrapGifs.length );
      gifSrc = unwrapGifs[gifNum];
    } else if (type ===  gifType.takeGift) {
      const gifNum = ( this.props.actionNum % takeGifs.length );
      gifSrc = takeGifs[gifNum];
    } else if (type === gifType.keep) {
      const gifNum = ( this.props.actionNum % keepGifs.length );
      gifSrc = keepGifs[gifNum];
    }

    

    return (
      <div className="Giphy-div">
        <img src={gifSrc} alt="gif" className="ImageSize" />
      </div>
    );
  }
}